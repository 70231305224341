import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import AuthService from '../../service/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordPage implements OnInit {
  recoverEmail = new FormControl('');
  status: string;

  constructor(
    private authService: AuthService) {
  }

  ngOnInit(): void {}

  onSubmit(): void {
    if (this && this.recoverEmail) {
      this.authService.forgotPassword(this.recoverEmail.value)
        .subscribe(
          (val) => {
            this.status = `An email is sent to ${this.recoverEmail.value}. Please check and reset password.`;
          },
          response => {
            this.status = response.error.message[0].messages[0].message;
            console.log("POST call in error ", response.error.message[0].messages[0].message);
          },
          () => {
            console.log("The POST observable is now completed.");
          })
    }
  }
}
