import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from "lodash";
import { CategoryCard } from '../../models/CategoryCard';

const CategoriesWithMerchant = gql`
  query CategoriesWithMerchant {
    categories {
      id
      name
      merchants(limit: 10) {
        id
        name
        address1
        address2
        city
        state
      }
    }
  }
`;

@Component({
  selector: 'app-popular-category-cards',
  templateUrl: './popular-category-cards.component.html',
  styleUrls: ['./popular-category-cards.component.css']
})
export class PopularCategoryCardsComponent implements OnInit {
  @Input() categoryCards: CategoryCard[];
  categories: any[];
  loading = true;
  categoryIndex: number;
  merchantIndex: number;
  private querySubscription: Subscription;
  constructor(private apollo: Apollo, translate: TranslateService) {
    this.querySubscription = this.apollo
      .watchQuery<any>({
        query: CategoriesWithMerchant,
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.loading = loading;
        
        if(data && data.categories) {
          this.categories = data.categories;
          this.categories = data.categories.map(cate => {
            let cateCopy = {...cate};
            if(cate && cate.merchants) {
              cateCopy.merchants = cate.merchants.map((merchant, index) => ({
                id: merchant.id,
                imageUrl: `assets/images/${cate.name}/${index+1}.jpg`,
                name: merchant.name,
                city:  merchant.city,
                state: merchant.state,
              }));
            }
            if(translate.currentLang == 'zh') {
              cateCopy.name = this.translateCategory(cate.name, 'zh');
            }
            return cateCopy;
          });
        }
      });
    translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if(translate.currentLang == 'en') {
          this.categoryCards = [
            {
              icon: 'flaticon-cutlery',
              category: 'Restaurant',
              num: 489,
            },
            {
              icon: 'flaticon-null',
              category: 'Entertainment',
              num: 489,
            },
            {
              icon: 'flaticon-money',
              category: 'Finance',
              num: 194,
            },
            {
              icon: 'flaticon-event',
              category: 'Education',
              num: 482,
            },
            {
              icon: 'flaticon-medical-book',
              category: 'Health',
              num: 1472,
            },
            {
              icon: 'flaticon-car',
              category: 'Transportation',
              num: 489,
            },
          ];
        } else if(translate.currentLang == 'zh') {
          this.categoryCards = [
            {
              icon: 'flaticon-cutlery',
              category: '餐廳',
              num: 489,
            },
            {
              icon: 'flaticon-null',
              category: '娛樂',
              num: 489,
            },
            {
              icon: 'flaticon-money',
              category: '金融服務',
              num: 194,
            },
            {
              icon: 'flaticon-event',
              category: '教育',
              num: 482,
            },
            {
              icon: 'flaticon-medical-book',
              category: '健康',
              num: 1472,
            },
            {
              icon: 'flaticon-car',
              category: '交通運輸',
              num: 489,
            },
          ];
        }
      }
    );
   }

  ngOnInit(): void {
    this.categoryIndex = 0;
    this.merchantIndex = 0;
  }

  setCategoryIndex(index: number) {
    this.categoryIndex = index;
  }
  
  setMerchantIndex(index: number) {
    let merchantSize = 10;
    if(this.categories[this.categoryIndex].merchants) {
      merchantSize = this.categories[this.categoryIndex].merchants.length;
    }
    this.merchantIndex = index % merchantSize;
  }

  translateCategory(category: string, translateTo: string): string {
    if(translateTo == 'en') {
      switch(category) {
        case '餐廳': return 'Restaurant';
        case '金融': return 'Finance';
        case '健康': return 'Health';
        case '教育': return 'Education';
        case '交通運輸': return 'Transportation';
        case '娛樂': return 'Entertainment';
        default:
          return category;
      }
    } else if(translateTo == 'zh') {
      switch(category) {
        case 'Restaurant': return '餐廳';
        case 'Finance': return '金融';
        case 'Health': return '健康';
        case 'Education': return '教育';
        case 'Transportation': return '交通運輸';
        case 'Entertainment': return '娛樂';
        default:
          return category;
      }
    }
  }
}
