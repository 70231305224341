<div class="site-blocks-cover inner-page-cover overlay" style="background-image: url(assets/images/homepage.jpg);"
  data-aos="fade" data-stellar-background-ratio="0.5">
  <div class="container">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-md-10" data-aos="fade-up" data-aos-delay="400">
        <div class="row justify-content-center">
          <div class="col-md-8 text-center">
            <h1>{{'MENU.SIGN_UP' | translate}} / {{'MENU.LOGIN' | translate}}</h1>
            <p data-aos="fade-up" data-aos-delay="100"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <form action="#" class="p-5 bg-white" (ngSubmit)="signup()" #signUpForm="ngForm">
          <div class="form-group">
            <div>
              <label class="text-black" for="username">{{'CONTENT.USER_NAME' | translate}}</label>
              <input type="text" class="form-control" id="username" name="userName" [(ngModel)]="userName" required minlength="8" #name="ngModel">
              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors.required">
                  User name is required.
                </div>
                <div *ngIf="name.errors.minlength">
                  User name must be at least 8 characters long.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div>
              <label class="text-black" for="email">{{'CONTENT.EMAIL' | translate}}</label>
              <input type="email" class="form-control" id="email" name="signUpEmail" [(ngModel)]="signUpEmail" email required #signupEmail="ngModel">
              <div *ngIf="signupEmail.invalid && (signupEmail.dirty || signupEmail.touched)" class="alert alert-danger">
                <div *ngIf="signupEmail.errors.required">
                  Email is required.
                </div>
                <div *ngIf="signupEmail.errors.email">
                  Email format is invalid.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div>
              <label class="text-black" for="pass1">{{'CONTENT.PASSWORD' | translate}}</label>
              <input type="password" class="form-control" id="pass1" name="signUpPassword" [(ngModel)]="signUpPassword" required minlength="8" #signuppass1="ngModel">
              <div *ngIf="signuppass1.invalid && (signuppass1.dirty || signuppass1.touched)" class="alert alert-danger">
                <div *ngIf="signuppass1.errors.required">
                  Password is required.
                </div>
                <div *ngIf="signuppass1.errors.minlength">
                  Password must be at least 8 characters long.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div>
              <label class="text-black" for="pass2">{{'CONTENT.RETYPE_PASSWORD' | translate}}</label>
              <input type="password" class="form-control" id="pass2" name="signUpPassword2" [(ngModel)]="signUpPassword2" required minlength="8" #signuppass2="ngModel">
              <div *ngIf="signuppass2.invalid && (signuppass2.dirty || signuppass2.touched)" class="alert alert-danger">
                <div *ngIf="signuppass2.errors.required">
                  Password is required.
                </div>
                <div *ngIf="signUpPassword2 != signUpPassword">
                  Retyped password must be same as password.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div>
              <input type="submit" class="btn btn-primary btn-md text-white" [disabled]="!signUpForm.form.valid" value="{{'MENU.SIGN_UP' | translate}}"/>
            </div>
          </div>
          <div *ngIf="signUpError" class="alert alert-danger">
            {{signUpError}}
          </div>
        </form>
      </div>
      <div class="col-md-6">
        <form class="p-5 bg-white" (ngSubmit)="login()" #loginForm="ngForm">
          <div class="form-group">
            <div>
              <label class="text-black" for="loginEmail">{{'CONTENT.EMAIL' | translate}}</label>
              <input type="email" name="loginEmail" id="loginEmail" class="form-control" [(ngModel)]="loginEmail" email required #loginemail="ngModel">
              <div *ngIf="loginemail.invalid && (loginemail.dirty || loginemail.touched)" class="alert alert-danger">
                <div *ngIf="loginemail.errors.required">
                  Email is required.
                </div>
                <div *ngIf="loginemail.errors.email">
                  Email format is invalid.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div>
              <label class="text-black" for="loginPass">{{'CONTENT.PASSWORD' | translate}}</label>
              <input type="password" name="loginPassword" id="loginPass" class="form-control" [(ngModel)]="loginPassword" required minlength="8">
            </div>
          </div>
          <div class="form-group">
            <div>
              <input type="submit" class="btn btn-primary btn-md text-white" [disabled]="!loginForm.form.valid" value="{{'MENU.LOGIN' | translate}}" />
            </div>
          </div>
          <div *ngIf="loginError" class="alert alert-danger">
            {{loginError}}
          </div>
          <div class="form-group">
            <div>
              <a href="http://admin.doudoufish.com/connect/facebook" class="btn btn-info" role="button">Login With Facebook</a>
              <!-- <button type="button" class="btn btn-primary" (click)="loginWithFB()">Login With Facebook</button> -->
            </div>
          </div>
          <a routerLink="/forgot-password">Forgot Password?</a>
        </form>
      </div>
    </div>
  </div>
</div>
