import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { ListingItem } from '../../models/ListingItem';
import { API_HOST } from '../../config';

const MerchantsQuery = gql`
  query MerchantsQuery($start: Int, $text: String, $location: String) {
    merchants(start: $start, limit: 10, where: {
      _or: [
        { zipcode_contains: $location },
        { city_contains: $location },
        { state_contains: $location },
      ],
      name_contains: $text
    }) {
      id
      name
      alias
      phone
      email
      address1
      address2
      city
      state
      zipcode
      category {
        name
      }
    }
  }
`;

const MerchantsByCategoryQuery = gql`
  query MerchantsByCategoryQuery($category: String, $start: Int, $text: String, $location: String) {
    categories(where: {name: $category}) {
      id
      name
      merchants(start: $start, limit: 10, where: {
        name_contains: $text,
        _or: [
            { zipcode_contains: $location, },
            { state_contains: $location },
            { city_contains: $location },
        ]
        }) {
        id
        name
        alias
        phone
        email
        address1
        address2
        city
        state
        zipcode
      }
    }
  }
`;

const MerchantsCount = gql`
  query GetMerchantsCount($text: String, $location: String) {
    merchantsCount(where: {
      name_contains: $text,
      _or: [
        {
          zipcode_contains: $location,
        },
        {
          state_contains: $location
        },
        {
          city_contains: $location
        },
      ]
    })
  }
`;

const MerchantsCountWithCategory = gql`
  query GetMerchantsCount($categoryId: ID, $text: String, $location: String) {
    merchantsCount(where: {
      category: $categoryId,
      name_contains: $text,
      _or: [
        {
          zipcode_contains: $location,
        },
        {
          state_contains: $location
        },
        {
          city_contains: $location
        },
      ]
    })
  }
`;

const MerchantIdByName = gql`
  query GetMerchantsId($name: String!) {
    categories(where: {name: $name}) {
        _id
    }
  }
`;

const ReviewsByMerchantQuery = gql`
  query QueryReviewsByMerchant($merchant: ID!) {
    reviews(where: { merchant: $merchant }) {
      rating
    }
  }
`;

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.css']
})
export class ListingsPage implements OnInit, OnDestroy {
  categories =  [
    {
        "key": null,
        "name": "All",
    },
    {
        "key": "Restaurant",
        "name": "Restaurant",
    },
    {
        "key": "Entertainment",
        "name": "Entertainment",
    },
    {
        "key": "Finance",
        "name": "Finance",
    },
    {
        "key": "Education",
        "name": "Education",
    },
    {
        "key": "Health",
        "name": "Health",
    },
    {
        "key": "Transportation",
        "name": "Transportation",
    }
  ];
  @Input() listingItems: ListingItem[];
  page: number;
  pages: number[];
  count: number;
  loading: boolean = true;
  qParams = {};
  private merchantIdSubscription: Subscription;
  private merchantCountSubscription: Subscription;
  private querySubscription: Subscription;
  private reviewQuerySubscription: Subscription;
  selectedCategoryKey: string;
  selectedLocation: string;
  searchText: string;
  merchantsUrl: string = `${API_HOST}/merchants/count`;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.qParams = params;
      if(!params.page) {
        this.qParams = {...this.qParams, page:1};
      }
      this.page = params['page'] ? params['page'] - 1 : 0;
      if (params['category']) {
        this.merchantIdSubscription = this.apollo
          .watchQuery<any>({
            query: MerchantIdByName,
            variables: {
              name: params['category'],
            },
          }).valueChanges.subscribe(({ data, loading }) => {
            this.loading = loading;
            const categoryId = _.get(data, 'categories[0]._id');
            this.merchantCountSubscription = this.apollo
              .watchQuery<any>({
                query: MerchantsCountWithCategory,
                variables: {
                  categoryId,
                  text: params['text'] || '',
                  location: params['location'] || ''
                },
              }).valueChanges.subscribe(({ data, loading }) => {
                this.loading = loading;
                this.count = data['merchantsCount'];
                this.pages = Array<number>(Math.ceil(this.count / 10)).fill(1).map((x, i) => i);
                this.querySubscription = this.apollo
                  .watchQuery<any>({
                    query: MerchantsByCategoryQuery,
                    variables: {
                      category: params['category'],
                      start: this.page * 10,
                      text: params['text'] || '',
                      location: params['location'] || ''
                    },
                  })
                  .valueChanges.subscribe(({ data, loading }) => {
                    this.loading = loading;
                    this.listingItems = [];
                    if (_.get(data, 'categories[0].merchants')) {
                      data.categories[0].merchants.forEach(element => {
                        let { id, name, alias, phone, email, address1, address2, city, state, zipcode } = element;
                        let item: ListingItem = {
                          id,
                          title: name,
                          alias,
                          phone,
                          email,
                          address: address1 && `${address1} ${address2}`,
                          city,
                          state,
                          zipcode,
                          category: params['category']
                        }
                        this.reviewQuerySubscription = this.apollo.watchQuery<any>({
                          query: ReviewsByMerchantQuery,
                          variables: { merchant: id },
                        }).valueChanges.subscribe(({data}) => {
                          item.reviewCount = data.reviews.length;
                          if(data.reviews.length > 0) {
                            let counter = 0;
                            data.reviews.forEach(review => {
                              counter += review.rating;
                            });
                            item.rating = counter / data.reviews.length;
                          }
                        });
                        this.listingItems.push(item);
                      });
                    }
                  });
              });
          });
      } else {
        this.merchantCountSubscription = this.apollo
          .watchQuery<any>({
            query: MerchantsCount,
            variables: {
              text: params['text'] || '',
              location: params['location'] || ''
            },
          }).valueChanges.subscribe(({ data, loading }) => {
            this.loading = loading;
            this.count = data['merchantsCount'];
            this.pages = Array<number>(Math.ceil(this.count / 10)).fill(1).map((x, i) => i);
            this.querySubscription = this.apollo
              .watchQuery<any>({
                query: MerchantsQuery,
                variables: {
                  start: this.page * 10,
                  text: params['text'] || '',
                  location: params['location'] || ''
                },
              })
              .valueChanges.subscribe(({ data, loading }) => {
                this.loading = loading;
                this.listingItems = [];
                if (data.merchants) {
                  data.merchants.forEach(element => {
                    let { id, name, alias, phone, email, address1, address2, city, state, zipcode } = element;
                    let item: ListingItem = {
                      id,
                      title: name,
                      alias,
                      phone,
                      email,
                      address: address1 && `${address1} ${address2}`,
                      city,
                      state,
                      zipcode,
                      category: _.get(element, "category.name")
                    }
                    this.reviewQuerySubscription = this.apollo.watchQuery<any>({
                      query: ReviewsByMerchantQuery,
                      variables: { merchant: id },
                    }).valueChanges.subscribe(({data}) => {
                      item.reviewCount = data.reviews.length;
                      if(data.reviews.length > 0) {
                        let counter = 0;
                        data.reviews.forEach(review => {
                          counter += review.rating;
                        });
                        item.rating = counter / data.reviews.length;
                      }
                    });
                    this.listingItems.push(item);
                  });
                }
              });
          });
      }
    });
  }

  ngOnDestroy() {
    if (this.merchantCountSubscription) this.merchantCountSubscription.unsubscribe();
    if (this.merchantIdSubscription) this.merchantIdSubscription.unsubscribe();
    if (this.querySubscription) this.querySubscription.unsubscribe();
  }

  getQueryParam(newParam: object) {
    return {...this.qParams, ...newParam};
  }

  onTextChange(text: string): void {
    this.searchText = text;
  }

  onLocationChange(text: string): void {
    this.selectedLocation = text;
  }

  onCategorySelect(category: any): void {
    this.selectedCategoryKey = category;
  }

  onClickSearch(): void {
    let queryString: string[] = [];
    if(this.selectedCategoryKey) {
      queryString.push(`category=${this.selectedCategoryKey}`);
    }
    if(this.searchText) {
      queryString.push(`text=${this.searchText}`);
    }
    if(this.selectedLocation) {
      queryString.push(`location=${this.selectedLocation}`);
    }
    this.router.navigateByUrl(`/listings?${queryString.join('&')}`);
  }
}
