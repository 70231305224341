<div class="row align-items-stretch">
  <div class="col-sm-12 col-md-6 col-lg-2 my-2 my-lg-0" *ngFor="let categoryCard of categoryCards; let i = index;">
    <a class="popular-category h-100" [ngClass]="{'selected' : i == categoryIndex}" (click)="setCategoryIndex(i)">
      <span class="icon mb-3"><span class="{{categoryCard.icon}}"></span></span>
      <span class="caption mb-2 d-block">{{categoryCard.category}}</span>
      <span class="number">{{categoryCard.num}}</span>
    </a>
  </div>
</div>
<div class="row" *ngIf="!loading; else loadingMerchants">
  <div id="categoryCarousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators" >
      <li *ngFor="let merchant of categories[categoryIndex].merchants; let j = index;" data-slide-to="j" (click)="setMerchantIndex(j)" [ngClass]="{'active' : j == merchantIndex}"></li>
    </ol>
    <div class="carousel-inner" >
      <div class="carousel-item" *ngFor="let merchant of categories[categoryIndex].merchants; let k = index;" [ngClass]="{'active' : k == merchantIndex}">
        <img class="d-block w-100" src="{{merchant.imageUrl}}" alt="{{merchant.name}}">
        <div class="carousel-caption d-none d-md-block">
          <h5><a routerLink="/merchant" [queryParams]="{id: merchant.id}">{{merchant.name}}</a></h5>
          <p>{{merchant.city}}, {{merchant.state}}</p>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" role="button" data-slide="prev" (click)="setMerchantIndex(merchantIndex - 1)">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" role="button" data-slide="next" (click)="setMerchantIndex(merchantIndex + 1)">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<ng-template #loadingMerchants>
  <i nz-icon [nzType]="'loading'"></i>
</ng-template>
