<ngb-carousel #carousel [interval]="4000">
  <ng-template ngbSlide *ngFor="let img of images; index as i">
    <div class="carousel-caption">
      <a class="px-3 mb-3 bg-light-op-8 category rounded" routerLink="/listings" [queryParams]="{category: category}" >{{categoryDisplayName}}</a>
      <div *ngIf="id; else noId">
        <div class="fs-5 bg-light-op-8 rounded"><a routerLink="/merchant" [queryParams]="{id: id}">{{title}}</a></div>
      </div>
      <ng-template #noId>
        <div class="fs-5 bg-light-op-8 rounded">{{title}}</div>
      </ng-template>
      <p class="fs-5 bg-dark-op-8 rounded">{{content}}</p>
    </div>
    <a routerLink="/listings" [queryParams]="{category: category}" rel="nofollow noopener noreferrer">
      <div class="picsum-img-wrapper">
        <img [src]="img" alt="{{title}}">
      </div>
    </a>
  </ng-template>
</ngb-carousel>
