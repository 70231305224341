<header class="site-navbar" role="banner">
  <nav class="navbar navbar-expand-md" role="navigation">
    <div class="container">
      <a routerLink="/"><img class="site-logo" src="assets/images/doufish_logo.png" alt="Doufish" /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navmenu"
      >
        <i class="bi bi-list text-light"></i>
      </button>
      <div class="collapse navbar-collapse" id="navmenu">
        <ul class="navbar-nav ms-auto bg-md-dark p-2">
          <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true, isActive: true }"><a class="nav-link text-light"
              routerLink="/index">{{ 'MENU.HOME' | translate }}</a></li>
          <li class="nav-item"><a class="nav-link text-light" href="https://ebook.doudoufish.com/">{{ 'MENU.EBOOK' | translate }}</a></li>
          <li class="nav-item" routerLinkActive="active"><a class="nav-link text-light" routerLink="/contact">{{ 'MENU.CONTACT' | translate }}</a></li>
          <li class="nav-item" *ngIf="!username; else signedIn" routerLinkActive="active"><a class="nav-link text-light" routerLink="/login">{{ 'MENU.LOGIN' | translate }}</a></li>
          <ng-template #signedIn>
            <li class="nav-item dropdown">
              <a id="accountDropdown" class="nav-link dropdown-toggle text-light" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ 'MENU.ACCOUNT' | translate }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="accountDropdown">
                  <a class="dropdown-item" routerLink="/account">{{ 'MENU.ACCOUNT' | translate }}</a>
                  <a class="dropdown-item" (click)="signOut()">{{ 'MENU.LOG_OUT' | translate }}</a>
              </ul>
            </li>
          </ng-template>
          <li class="nav-item">
            <select class="nav-link" (change)="onLangSelect($event.target.value)">
              <option *ngFor="let lang of translate.getLangs()" [value]="lang">{{ getLanguage(lang) }}</option>
            </select>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>