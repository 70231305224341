import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import AuthService from '../../service/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupPage implements OnInit {
  userName: string;
  signUpEmail: string;
  signUpPassword: string;
  signUpPassword2: string;
  loginEmail: string;
  loginPassword: string;
  disableBtnSignIn: true;
  disableBtnSignUp: true;
  signUpError: string;
  loginError: string;

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  signup() {
    if (this.userName && this.signUpEmail && this.signUpPassword) {
      this.authService.register(this.userName, this.signUpEmail, this.signUpPassword)
        .subscribe(
          (val) => {
            console.log("User is signed up. ", val);
            this.router.navigateByUrl('/');
          },
          response => {
            this.signUpError = response.error.message[0].messages[0].message;
          },
          () => {
            console.log("The POST observable is now completed.");
          }
        );
    }
  }

  login() {
    if (this && this.loginEmail && this.loginPassword) {
      this.authService.login(this.loginEmail, this.loginPassword)
        .subscribe(
          (val) => {
            localStorage.setItem('token', val.jwt);
            localStorage.setItem('username', val.user.username);
            localStorage.setItem('user', val.user._id);
            localStorage.setItem('email', val.user.email);
            this.authService.loginPerformedObserver.next();
            this.router.navigateByUrl('/');
          },
          response => {
            this.loginError = response.error.message[0].messages[0].message;
            console.log("POST call in error", JSON.stringify(response.error.message[0].messages[0].message));
          },
          () => {
            console.log("The POST observable is now completed.");
          }
        );
    }
  }

  loginWithFB() {
    this.authService.loginWith('facebook').subscribe(
      (val) => {
        console.log(`## signup componnet loginWithFB val: `, val);
        localStorage.setItem('token', val.jwt);
        localStorage.setItem('username', val.user.username);
        localStorage.setItem('email', val.user.email);
        this.authService.loginPerformedObserver.next();
        this.router.navigateByUrl('/');
      },
      response => {
        this.loginError = response.error.message[0].messages[0].message;
        console.log("GET Facebook call in error", JSON.stringify(response.error.message[0].messages[0].message));
      },
      () => {
        console.log("The GET Facebook observable is now completed.");
      }
    );
  }
}
