import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import gql from 'graphql-tag';

const UserQuery = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      username
      fullname
      email
      avatar {
        url
      }
      createdAt
      location {
        city
        state
      }
      description
      socialMedia {
        name
        url
      }
    }
  }
`
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfilePage implements OnInit {

  loading: boolean = true;
  name: string;
  email: string;
  avatar: string;
  joinSince: string;
  city: string;
  state: string;
  description: string;

  private userSubscription: Subscription;

  constructor(private apollo: Apollo) {}

  ngOnInit(): void {
    this.userSubscription = this.apollo
      .watchQuery<any>({
        query: UserQuery,
        variables: {
          id: localStorage.getItem('user'),
        },
      }).valueChanges.subscribe(({ data, loading }) => {
        this.loading = loading;
        const { username, fullname, email, avatar, createdAt, location, description } = data.user;
        this.name = fullname || username;
        this.email = email;
        this.avatar = avatar.url;
        this.joinSince = createdAt;
        this.city = location.city;
        this.state = location.state;
        this.description = description || `You haven't told us yet...`;
      });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
