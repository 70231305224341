import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import AuthService from '../../service/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordPage implements OnInit {
  password = new FormControl('');
  confirmPassword = new FormControl('');
  status: string;
  code: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.code = params.code;
    })
  }

  onSubmit(): void {
    if (!this.code) {
      this.status = "Invalid URL";
      return
    }
    if (this && this.password.value && this.confirmPassword.value) {
      this.authService.resetPassword(this.code, this.password.value, this.confirmPassword.value)
        .subscribe(
          (val) => {
            this.status = `Success`;
            this.router.navigateByUrl('/');
          },
          response => {
            this.status = response.error.message[0].messages[0].message;
            console.log("POST call in error ", response.error.message[0].messages[0].message);
          },
          () => {
            console.log("The POST observable is now completed.");
          })
    }
  }
}
