import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { API_HOST } from '../config';

@Injectable({
  providedIn: 'root'
})
export default class AuthService {
  public loginPerformedObserver: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) { }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post<any>(`${API_HOST}/auth/local/register`, {username, email, password});
  }

  login(identifier: string, password: string ): Observable<any> {
    return this.http.post<any>(`${API_HOST}/auth/local`, {identifier, password});
  }

  loginWith(provider: string): Observable<any> {
    return this.http.get<any>(`${API_HOST}/connect/${provider}/redirect`);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${API_HOST}/auth/forgot-password`, {email});
  }

  resetPassword(code: string, password: string, passwordConfirmation: string) {
    return this.http.post<any>(`${API_HOST}/auth/reset-password`, {code, password, passwordConfirmation});
  }
}
