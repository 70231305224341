<div class="site-blocks-cover inner-page-cover overlay" style="background-image: url(assets/images/homepage.jpg); height: 1rem;">
</div>

<div class="site-section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div *ngIf="!loading; else loadingItems">
          <div *ngFor="let listingItem of listingItems" class="d-block d-md-flex listing-horizontal">
            <a href="#" class="img d-block" style="background-image: url('assets/images/img_1.jpg')">
              <span class="category">{{listingItem.category}}</span>
            </a>
            <div class="lh-content">
              <!-- <a href="#" class="bookmark"><span class="icon-heart"></span></a> -->
              <h3 *ngIf="listingItem.title"><a routerLink="/merchant" [queryParams]="{id: listingItem.id}">{{listingItem.title}}</a></h3>
              <h4 *ngIf="listingItem.alias"><a routerLink="/merchant" [queryParams]="{id: listingItem.id}">{{listingItem.alias}}</a></h4>
              <p>{{listingItem.phone}} {{listingItem.email}}</p>
              <p>{{listingItem.address}}</p>
              <p><span *ngIf="listingItem.city">{{listingItem.city}},</span> {{listingItem.state}}
                {{listingItem.zipcode}}</p>
              <p *ngIf="listingItem.reviewCount">
                <nz-rate [(ngModel)]="listingItem.rating" [nzDisabled]="true"></nz-rate>
                <span>{{listingItem.reviewCount}}</span>
              </p>
            </div>
          </div>
          <div class="col-12 mt-5 text-center">
            <div class="custom-pagination">
              <ng-container *ngFor="let pg of pages">
                <span *ngIf="pg == page; else pageLink">{{pg + 1}}</span>
                <ng-template #pageLink>
                  <ng-container *ngIf="(page - pg > 0 && page - pg <= 5) || (pg - page >= 0 && pg - page <= 5)">
                    <a routerLink="/listings" [queryParams]="getQueryParam({page:pg + 1})">{{pg + 1}}</a>
                  </ng-container>
                </ng-template>
              </ng-container>
              <a routerLink="/listings" [queryParams]="getQueryParam({page:pages && pages.length})">Last</a>
            </div>
            <div>Find {{count}} merchants.</div>
          </div>
          <!-- <nav aria-label="Search results page navigation">
            <ul class="pagination pg-blue justify-content-center pagination-lg">
              <li class="page-item">
                <a class="page-link" aria-label="Previous" tabindex="-1" mdbWavesEffect>
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" mdbWavesEffect>1</a></li>
              <li class="page-item"><a class="page-link" mdbWavesEffect>2 <span class="sr-only">(current)</span></a></li>
              <li class="page-item"><a class="page-link" mdbWavesEffect>3</a></li>
              <li class="page-item">
                <a class="page-link" aria-label="Next" mdbWavesEffect>
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav> -->
        </div>
        <ng-template #loadingItems>
          <i nz-icon [nzType]="'loading'"></i>
        </ng-template>
      </div>
      <!-- Right side bar -->
      <div class="col-lg-3 ml-auto">
        <div class="mb-5">
          <h3 class="h5 text-black mb-3">Filters</h3>
          <form action="#" method="post">
            <div class="form-group">
              <input type="text" placeholder="{{ 'CONTENT.WHAT_ARE_YOU_LOOKING_FOR' | translate }}" class="form-control" (change)="onTextChange($event.target.value)">
            </div>
            <div class="form-group">
              <div class="select-wrap">
                <span class="icon"><span class="icon-keyboard_arrow_down"></span></span>
                <select class="form-control" (change)="onCategorySelect($event.target.value)">
                  <option *ngFor="let category of categories" [value]="category.key">{{ category.name }}</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <!-- select-wrap, .wrap-icon -->
              <div class="wrap-icon">
                <span class="icon icon-room"></span>
                <input type="text" placeholder="{{ 'CONTENT.LOCATION' | translate }}" class="form-control" (change)="onLocationChange($event.target.value)">
              </div>
            </div>
            <div>
              <input type="submit" class="btn text-white btn-primary" value="{{ 'CATEGORY.SEARCH' | translate }}" (click)="onClickSearch()">
            </div>
          </form>
        </div>
        <div class="mb-5">
          <form action="#" method="post">
            <div class="form-group">
              <p>Radius around selected destination</p>
            </div>
            <div class="form-group">
              <input type="range" min="0" max="100" value="20" data-rangeslider>
            </div>
          </form>
        </div>
        <div class="mb-5">
          <form action="#" method="post">
            <div class="form-group">
              <p>Category 'Restaurant' is selected</p>
              <p>More filters</p>
            </div>
            <div class="form-group">
              <ul class="list-unstyled">
                <li>
                  <label for="option1">
                    <input type="checkbox" id="option1">
                    Coffee
                  </label>
                </li>
                <li>
                  <label for="option2">
                    <input type="checkbox" id="option2">
                    Vegetarian
                  </label>
                </li>
                <li>
                  <label for="option3">
                    <input type="checkbox" id="option3">
                    Vegan Foods
                  </label>
                </li>
                <li>
                  <label for="option4">
                    <input type="checkbox" id="option4">
                    Sea Foods
                  </label>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Sign Up -->
<app-signup-bar></app-signup-bar>

<nz-back-top></nz-back-top>
