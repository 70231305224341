<div class="mt-3">
  <form>
    <div class="mb-3">
      <label for="fullname" class="form-label">Full name</label>
      <input type="text" class="form-control" nz-input [(ngModel)]="fullname" name="fullname">
    </div>
    <div class="mb-3">
      <label for="city" class="form-label">City</label>
      <input type="text" class="form-control" nz-input [(ngModel)]="city" name="city">
    </div>
    <div class="mb-3">
      <label for="state" class="form-label">State</label>
      <input type="text" class="form-control" nz-input [(ngModel)]="state" name="state">
    </div>
    <div class="mb-3">
      <label for="description">About me</label>
      <textarea class="form-control" placeholder="Let others know you" nz-input [(ngModel)]="description" name="description"></textarea>
    </div>
    <button type="submit" class="btn btn-primary" (click)="onUpdateProfile()">Save</button>
  </form>
</div>
<router-outlet></router-outlet>