import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import AuthService from '../../../service/auth.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  username: string;
  private _loginPerformed: Subject<any>;

  getLanguage(lang: string): string {
    switch(lang) {
      case 'en': return 'English';
      case 'zh': return '繁體中文';
    }
  }

  onLangSelect(lang: string): void {
    switch(lang) {
      case 'en': 
        localStorage.setItem('lang', 'en');
        this.translate.use(lang);
        break;
      case 'zh': 
        localStorage.setItem('lang', 'zh');
        this.translate.use(lang);
    }
  }

  constructor(public translate: TranslateService,
    private router: Router,
    private authService: AuthService) { 
    //translate.setDefaultLang('en');
    let langs = ['en', 'zh'];
    let language = localStorage.getItem('lang');
    if(language) {
      langs = langs.filter(l => l != language);
      langs.unshift(language);
    }
    translate.addLangs(langs);
    const browserLang = language || translate.getBrowserLang();
    translate.use(browserLang.match(/en|zh/) ? browserLang: 'en');
  }

  ngOnInit(): void {
    this._loginPerformed= this.authService.loginPerformedObserver;
    this._loginPerformed.subscribe((value) => {
      this.username = localStorage.getItem('username');
    });
    this.username = localStorage.getItem('username');
  }

  signOut(): void {
    this.username = null;
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('user');
    this.router.navigateByUrl('/');
  }
}
