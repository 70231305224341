import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ListingItemsComponent } from './components/listing-items/listing-items.component';
import { MainSiteBlockComponent } from './components/layout/main-site-block/main-site-block.component';
import { PopularCategoryCardsComponent } from './components/popular-category-cards/popular-category-cards.component';
import { SignupBarComponent } from './components/signup-bar/signup-bar.component';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { GraphQLModule } from './graphql.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PhotoBrowserComponent } from './components/photo-browser/photo-browser.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { BannerComponent } from './components/banner/banner.component';
// Ant Design Modules
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzUploadModule } from 'ng-zorro-antd/upload';
// Ant Design Icons
import { IconDefinition } from '@ant-design/icons-angular';
import { DeleteOutline, DownloadOutline, PlusOutline, LoadingOutline, PictureTwoTone } from '@ant-design/icons-angular/icons';

const icons: IconDefinition[] = [ DeleteOutline, DownloadOutline, PlusOutline, LoadingOutline, PictureTwoTone ];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SignupBarComponent,
    ListingItemsComponent,
    MainSiteBlockComponent,
    PopularCategoryCardsComponent,
    PhotoBrowserComponent,
    routingComponents,
    CarouselComponent,
    BannerComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    NgbModule,
    NzBackTopModule,
    NzBreadCrumbModule,
    NzButtonModule,
    NzDividerModule,
    NzGridModule,
    NzIconModule.forRoot(icons),
    NzImageModule,
    NzInputModule,
    NzLayoutModule,
    NzMessageModule,
    NzModalModule,
    NzRateModule,
    NzTabsModule,
    NzUploadModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
