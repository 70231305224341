<div>
    <div *ngFor="let merchant of likes" class="d-block d-md-flex listing-horizontal">
        <a href="#" class="img d-block" style="background-image: url('assets/images/img_1.jpg')">
            <span class="category">{{merchant.category}}</span>
        </a>
        <div class="lh-content">
            <span class="fas fa-times" style="position:absolute; right:1rem;"
                (click)="onDelele(merchant.likeId)"></span>
            <h3 *ngIf="merchant.title"><a routerLink="/merchant"
                    [queryParams]="{id: merchant.id}">{{merchant.title}}</a></h3>
            <h4 *ngIf="merchant.alias"><a routerLink="/merchant"
                    [queryParams]="{id: merchant.id}">{{merchant.alias}}</a></h4>
            <p>{{merchant.phone}} {{merchant.email}}</p>
            <p>{{merchant.address}}</p>
            <p><span *ngIf="merchant.city">{{merchant.city}},</span> {{merchant.state}}
                {{merchant.zipcode}}</p>
        </div>
    </div>
    <div class="col-12 mt-5 text-center">
        <div>Find {{likes.length}} likes.</div>
    </div>
</div>
<router-outlet></router-outlet>
