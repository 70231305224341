<div class="site-blocks-cover inner-page-cover overlay" style="background-image: url(assets/images/homepage.jpg);"
  data-aos="fade" data-stellar-background-ratio="0.5">
  <div class="container">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-md-10" data-aos="fade-up" data-aos-delay="400">
        <div class="row justify-content-center">
          <div class="col-md-8 text-center">
            <h1>Forgot Password</h1>
            <p data-aos="fade-up" data-aos-delay="100"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-6 mb-5">
        <form (ngSubmit)="onSubmit()" class="p-5 bg-white" style="margin-top: -150px;">
          <div class="row form-group">
            <div class="col-md-12">
              <label class="text-black" for="email">Email</label>
              <input type="email" class="form-control" id="email" name="recoverEmail" [formControl]="recoverEmail" email required>
              <div *ngIf="recoverEmail.invalid && (recoverEmail.dirty || recoverEmail.touched)" class="alert alert-danger">
                <div *ngIf="recoverEmail.errors.required">
                  Email is required.
                </div>
                <div *ngIf="recoverEmail.errors.email">
                  Email format is invalid.
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary btn-md text-white" [disabled]="recoverEmail.invalid">Send</button>
            </div>
          </div>
          <div *ngIf="status" class="alert">
            {{status}}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>