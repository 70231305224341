<div class="container">
    <div class="row border rounded my-2">
        <div class="col-md-7">
            <div class="fs-3">John Doe</div>
            <div class="fs-5 my-2">
                <i class="fas fa-map-marker"></i>
                {{city}}, {{state}}
            </div>
            <div class="fs-5 d-flex justify-content-between">
                <div>
                    <i class="fas fa-user-friends"></i><a href="#">Friends</a>
                </div>
                <div>
                <i class="fas fa-star"></i><a href="#">Reviews</a></div>
                <div> <i class="fas fa-camera"></i><a href="#">Photos</a></div>
            </div>
        </div>
        <div class="col-md-5 d-flex flex-sm-column justify-content-around fs-5">
            <div><i class="fas fa-plus-square"></i><a href="#">Profile Photos</a></div>
            <div><i class="fas fa-id-card"></i><a routerLink="/account/edit">Update Profile</a></div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row border rounded my-2">
        <div class="col-md-7">
            <div class="fs-3">About {{name}}</div>
            <div class="fs-5 fw-bold">Joined Since</div>
            <div>{{joinSince | date: 'MMM d, y'}}</div>
            <div class="fs-5 fw-bold">Things I love</div>
            <p class="font-monospace">{{description}}</p>
        </div>
        <div class="col-md-5 fs-5">
            <div class="fs-3">Social Media</div>
            <i class="fab fa-instagram"></i>
            <i class="fab fa-facebook-square"></i>
        </div>
    </div>
</div>

<div class="container">
    <div class="fs-4 fw-bold">Recent Reviews</div>
    <div class="row">
        <div class="col-md-3">
            <img class="rounded-corner" src="https://images.unsplash.com/photo-1460306855393-0410f61241c7">
            <div>
                <i class="fa fa-star highlight"></i>
                <i class="fa fa-star highlight"></i>
                <i class="fa fa-star highlight"></i>
                <i class="fa fa-star-half-alt highlight"></i>
                <i class="far fa-star highlight"></i>
                <p>7/30/2020</p>
            </div>
        </div>
        <div class="col-md-9">
            <div>
                <h5>Maria Burger</h5>
                <div>
                    <span>$$·Steak, Chinese</span>
                </div>
                <div>
                    <i class="fas fa-map-marker-alt"></i><span> 3640 Shallowford Rd #44, Doraville,
                        Georgia</span>
                </div>
                <div>
                    <span class="bg-info">Open 11:30 AM - 7:30 PM</span>
                </div>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                in
                culpa qui officia deserunt mollit anim id est laborum.</p>
        </div> 
    </div>
    <div class="row">
        <div class="col-md-3">
            <img class="rounded-corner" src="https://images.unsplash.com/photo-1460306855393-0410f61241c7">
            <div>
                <i class="fa fa-star highlight"></i>
                <i class="fa fa-star highlight"></i>
                <i class="fa fa-star highlight"></i>
                <i class="fa fa-star highlight"></i>
                <i class="fa fa-star-half-alt highlight"></i>
                <p>6/30/2020</p>
            </div>
        </div>
        <div class="col-md-9">
            <div>
                <h5>Maria Burger</h5>
                <div>
                    <span>$$·Steak, Chinese</span>
                </div>
                <div>
                    <i class="fas fa-map-marker-alt"></i><span> 3640 Shallowford Rd #44, Doraville,
                        Georgia</span>
                </div>
                <div>
                    <span class="bg-info">Open 11:30 AM - 7:30 PM</span>
                </div>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                in
                culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
    </div>
</div>
<router-outlet></router-outlet>