import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

import { AuthGuard } from './auth/auth.guard';
import { HomePage } from './pages/home/home.component';
import { AboutPage } from './pages/about/about.component';
import { BlogPage } from './pages/blog/blog.component';
import { ContactPage } from './pages/contact/contact.component';
import { ListingsPage } from './pages/listings/listings.component';
import { SignupPage } from './pages/signup/signup.component';
import { MerchantPage } from './pages/merchant/merchant.component';
import { AccountPage } from './pages/account/account.component';
import { LoadingPage } from './pages/loading/loading.component';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordPage } from './pages/reset-password/reset-password.component';
import { UserLikesPage } from './pages/user-likes/user-likes.component';
import { ProfilePage } from './pages/profile/profile.component';
import { UserReviewsPage } from './pages/user-reviews/user-reviews.component';
import { UserEditPage } from './pages/user-edit/user-edit.component';

import { NzModalModule } from 'ng-zorro-antd/modal';

const routes: Routes = [
  { path: 'about', component: AboutPage },
  { path: 'about.html', component: AboutPage },
  { path: 'account', component: AccountPage, canActivate: [ AuthGuard ],
    children: [
      {
        path:  'likes',
        component:  UserLikesPage
      },
      {
        path:  'reviews',
        component:  UserReviewsPage
      },
      {
        path:  'edit',
        component:  UserEditPage
      },
      {
        path:  '',
        component:  ProfilePage
      }
    ]
  },
  { path: 'connect/facebook/redirect', component: LoadingPage },
  { path: 'blog', component: BlogPage },
  { path: 'blog.html', component: BlogPage },
  { path: 'contact', component: ContactPage },
  { path: 'contact.html', component: ContactPage },
  { path: 'listings', component: ListingsPage },
  { path: 'listings.html', component: ListingsPage },
  { path: 'login', component: SignupPage },
  { path: 'login.html', component: SignupPage },
  { path: 'merchant', component: MerchantPage },
  { path: 'forgot-password', component: ForgotPasswordPage },
  { path: 'reset-password', component: ResetPasswordPage },
  { path: 'signup', component: SignupPage },
  { path: 'signup.html', component: SignupPage },
  { path: 'index', component: HomePage },
  { path: 'index.html', component: HomePage },
  { path: '', component: HomePage },
  { path: '**', component: HomePage },
];

@NgModule({
  imports: [NzModalModule, RouterModule.forRoot(routes), CommonModule, FormsModule, ReactiveFormsModule],
  exports: [RouterModule],
  declarations: [UserLikesPage, UserReviewsPage, UserEditPage]
})
export class AppRoutingModule { }
export const routingComponents = [
  HomePage, 
  ListingsPage,
  BlogPage,
  AboutPage, 
  ContactPage,
  SignupPage,
  AccountPage,
  ProfilePage,
  MerchantPage,
  ForgotPasswordPage,
  ResetPasswordPage,
]
