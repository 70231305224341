/**
 * Home page banner.
 */
import { Component, OnInit } from '@angular/core';

const BANNERS = [
  'assets/images/Banner/1.jpg',
  'assets/images/Banner/2.jpg',
  'assets/images/Banner/3.jpg',
  'assets/images/Banner/4.jpg',
  'assets/images/Banner/5.jpg',
  'assets/images/Banner/5.jpg',
  'assets/images/Banner/6.jpg',
  'assets/images/Banner/7.jpg',
  'assets/images/Banner/8.jpg',
  'assets/images/Banner/9.jpg',
  'assets/images/Banner/10.jpg'
]

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  banners: string[];
  constructor() { }

  ngOnInit(): void {
    this.banners = BANNERS;
  }

}
