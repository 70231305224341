import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { API_HOST } from '../../config';

const MerchantByIdQuery = gql`
  query MerchantByIdQuery($merchantId: ID!) {
    merchant(id: $merchantId) {
      name
      alias
      address1
      address2
      city
      state
      zipcode
      website
      phone
      category {
          name
      }
      banner {
        url
      }
      photos {
        url
      }
    }
  }
`;

const LikeByUserMerchantQuery = gql`
  query QueryLikesByUserMerchant($user: ID!, $merchant: ID!) {
    likes(where: { user: $user, merchant: $merchant }) {
      id
    }
  }
`;

const CreateLike = gql`
  mutation CreateLikeForMerchant($user: ID!, $merchant: ID!) {
    createLike(input: {data: {user: $user, merchant: $merchant}}) {
      like {
        id
        merchant {
          id
        }
        user {
          id
        }
      }
    }
  }
`;

const DeleteLike = gql`
  mutation DeleteLikeById($likeId: ID!) {
    deleteLike(input: {where: {id: $likeId}}) {
      like {
        id
      }
    }
  }
`;

const CreateReview = gql`
  mutation CreateReviewForMerchant($reviewInput: ReviewInput!) {
    createReview(input: { data: $reviewInput }) {
      review {
        id
        title
        content
        rating
        merchant {
          id
          name
          alias
        }
        user {
          id
          username
        }
      }
    }
  }
`;

const ReviewsByMerchantQuery = gql`
  query QueryReviewsByMerchant($merchant: ID!) {
    reviews(where: { merchant: $merchant }) {
      id
      title
      content
      rating
      user {
          username
            avatar {
              url
          }
      }
      images {
        name
        alternativeText
        caption
        url
        previewUrl
      }
      createdAt
      updatedAt
    }
  }
`;

function getBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.scss']
})
export class MerchantPage implements OnInit {
  public href: string = "";
  merchantId: string;
  name: string;
  alias: string;
  category: string;
  address: string;
  banner: string;
  photos: string[];
  website: string;
  phone: string;
  loading: boolean;
  googleMapUrl: SafeResourceUrl;
  likeByUser: boolean;
  description: string;
  private likeId: string;
  private merchantQuerySubscription: Subscription;
  private likeQuerySubscription: Subscription;
  private reviewQuerySubscription: Subscription;
  rate: number;
  reviewTitle: string;
  reviewContent: string;
  userId: string;
  reviews: any;
  overallRating: number;
  reviewCount: number;
  reviewUploadImages: NzUploadFile[] = [];
  reviewUploadImageIDs: object = {};
  previewImage: string | undefined = '';
  previewVisible = false;
  uploadFileName = '';

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
    private sanitizer: DomSanitizer,
    private message: NzMessageService,
  ) { }

  ngOnInit(): void {
    this.href = this.router.url;
    this.userId = localStorage.getItem('user');
    this.route.queryParams.subscribe(params => {
      this.merchantId = params['id'];
      if (this.merchantId) {
        this.merchantQuerySubscription = this.apollo
          .watchQuery<any>({
            query: MerchantByIdQuery,
            variables: {
              merchantId: params['id'],
            },
          })
          .valueChanges.subscribe(({ data, loading }) => {
            this.loading = loading;
            const merchant = data.merchant;
            this.name = merchant.name;
            this.alias = _.get(merchant, 'alias');
            this.category = _.get(merchant, 'category.name');
            this.address = merchant.address1 + ' '
              + (merchant['address2'] || '') + ', '
              + _.get(merchant, 'city', '') + ', '
              + _.get(merchant, 'state', '') + ' '
              + _.get(merchant, 'zipcode', '');
            this.website = merchant.website;
            this.phone = merchant.phone;
            this.banner = _.get(merchant, 'banner.url');
            if (_.get(merchant, 'photos').length) {
              this.photos = _.get(merchant, 'photos').map(photo => photo.url);
            }
            this.googleMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/search?q=${encodeURIComponent(this.address)}&key=AIzaSyAkQLxVYD61vKxY_lemmHkC6BmBwos8jU0`);
          });
        if(this.userId) {
          this.likeQuerySubscription = this.apollo
          .watchQuery<any>({
            query: LikeByUserMerchantQuery,
              variables: {
                user: this.userId,
                merchant: params['id'],
              },
          }).valueChanges.subscribe(({data}) => {
            this.likeByUser = data.likes && data.likes.length > 0;
            if(this.likeByUser) {
              this.likeId = _.get(data, 'likes[0].id');
            }
          });
        }
        this.reviewQuerySubscription = this.apollo.watchQuery<any>({
          query: ReviewsByMerchantQuery,
          variables: { merchant: params['id'] },
        }).valueChanges.subscribe(({data}) => {
          this.reviews = data.reviews;
          this.reviewCount = data.reviews.length;
          if(data.reviews.length > 0) {
            let counter = 0;
            data.reviews.forEach(review => {
              counter += review.rating;
            });
            this.overallRating = counter / data.reviews.length;
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.merchantQuerySubscription) this.merchantQuerySubscription.unsubscribe();
    if (this.likeQuerySubscription) this.likeQuerySubscription.unsubscribe();
    if (this.reviewQuerySubscription) this.reviewQuerySubscription.unsubscribe();
  }

  onLikeClick() {
    if(this.userId) {
      this.apollo.mutate({
        mutation: CreateLike,
        variables: {
          user: this.userId,
          merchant: this.merchantId
        }
      }).subscribe(({ data }) => {
        // console.log('Create like data:', data);
        this.likeId = _.get(data, 'createLike.like.id');
      },(error) => {
        console.log('there was an error sending the query', error);
      });
      this.likeByUser = true;
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  onUnlikeClick() {
    this.apollo.mutate({
      mutation: DeleteLike,
      variables: {
        likeId: this.likeId,
      }
    }).subscribe(({ data }) => {
      // console.log('Unlike data:', data);
    },(error) => {
      console.log('there was an error sending the query', error);
    });
    this.likeByUser = false;
  }

  onWriteReview(id) {
    if(this.userId) {
      let el = document.getElementById(id);
      el.scrollIntoView();
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  onSubmitReview() {
    if(!this.reviewTitle || _.isEmpty(this.reviewTitle.replace(/\s/g,''))) {
      this.message.create('warning', `Title can not be empty`);
      return;
    }
    if(!this.reviewContent || _.isEmpty(this.reviewContent.replace(/\s/g,''))) {
      this.message.create('warning', `Review can not be empty`);
      return;
    }
    this.apollo.mutate({
      mutation: CreateReview,
      variables: {
        reviewInput: {
          user: this.userId,
          merchant: this.merchantId,
          title: this.reviewTitle,
          content: this.reviewContent,
          rating: this.rate,
          images: Object.values(this.reviewUploadImageIDs)
        }
      }
    }).subscribe(({ data }) => {
      window.location.reload();
    },(error) => {
      console.log('Sumbit review error', error);
    });
  }

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.uploadFileName = file.name;
    return true;
  }

  handleUpload = (item) => {
    const formData = new FormData();
    formData.append('files', item.file as any, this.uploadFileName);
    // formData.append('ref', 'merchant');
    // formData.append('refId', this.merchantId);
    // formData.append('field', 'photos');

    return this.http.post(`${API_HOST}/upload`, formData, { headers: new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    })}).subscribe(
      res => {
        const uid = this.reviewUploadImages.slice(-1)[0].uid;
        this.reviewUploadImageIDs[uid] = res[0].id.toString();
        item.onSuccess(item.file);
      },
      (err) => {
        item.onError(err, item.file);
      }
    );
  }

  // handleRemoveUpload = (file: NzUploadFile): boolean | Observable<boolean> => {
    
  // }
}
