<div class="container">
  <!-- Images used to open the lightbox -->
  <div class="myrow">
    <div class="column">
      <img src="https://images.unsplash.com/photo-1508467658828-f1865587990e" (click)="open(photoVideoModal)"
        class="hover-shadow">
    </div>
    <div class="column">
      <img src="https://images.unsplash.com/photo-1568792666129-01aeffaab120" (click)="open(photoVideoModal)"
        class="hover-shadow">
    </div>
    <div class="column">
      <img src="https://images.unsplash.com/photo-1589140617386-c5225cd43b9c" (click)="open(photoVideoModal)"
        class="hover-shadow">
    </div>
    <div class="column">
      <img src="https://images.unsplash.com/photo-1536228150971-fd7afa00c654" (click)="open(photoVideoModal)"
        class="hover-shadow">
    </div>
  </div>
  <!-- The Modal/Lightbox -->
  <ng-template #photoVideoModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Merchant Name</h4>
      <span class="close" (click)="modal.dismiss('Cross click')">&times;</span>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <div class="slides">
          <div class="numbertext">1 / 4</div>
          <img src="https://images.unsplash.com/photo-1508467658828-f1865587990e" style="width:100%">
        </div>

        <div class="slides">
          <div class="numbertext">2 / 4</div>
          <img src="https://images.unsplash.com/photo-1568792666129-01aeffaab120" style="width:100%">
        </div>

        <div class="slides">
          <div class="numbertext">3 / 4</div>
          <img src="https://images.unsplash.com/photo-1589140617386-c5225cd43b9c" style="width:100%">
        </div>

        <div class="slides">
          <div class="numbertext">4 / 4</div>
          <img src="https://images.unsplash.com/photo-1536228150971-fd7afa00c654" style="width:100%">
        </div>

        <!-- Next/previous controls -->
        <a class="prev">&#10094;</a>
        <a class="next">&#10095;</a>

        <!-- Caption text -->
        <div class="caption-container">
          <p id="caption"></p>
        </div>

        <!-- Thumbnail image controls -->
        <div class="myrow">
          <div class="column">
            <img class="demo cursor" src="https://images.unsplash.com/photo-1508467658828-f1865587990e"
              onclick="currentSlide(1)" style="width:100%" alt="Nature">
          </div>

          <div class="column">
            <img class="demo cursor" src="https://images.unsplash.com/photo-1568792666129-01aeffaab120"
              onclick="currentSlide(2)" style="width:100%" alt="Snow">
          </div>

          <div class="column cursor">
            <img class="demo cursor" src="https://images.unsplash.com/photo-1589140617386-c5225cd43b9c"
              onclick="currentSlide(3)" style="width:100%" alt="Mountains">
          </div>

          <div class="column">
            <img class="demo cursor" src="https://images.unsplash.com/photo-1536228150971-fd7afa00c654"
              onclick="currentSlide(4)" style="width:100%" alt="Lights">
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>