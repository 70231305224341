<div class="site-blocks-cover inner-page-cover overlay" style="background-image: url(assets/images/homepage.jpg); height: 1rem;">
</div>
<div class="container">
  <div class="row" *ngIf="!loading; else loadingMerchant">
    <div class="col-lg-9">
      <nz-breadcrumb>
        <nz-breadcrumb-item>
          <a [routerLink]="['/']">Home</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>
          <a routerLink="/listings" [queryParams]="{category: category}">{{category}}</a>
        </nz-breadcrumb-item>
      </nz-breadcrumb>
      <div class="mb-large">
        <div>
          <div class="fs-2">{{name}}</div>
          <div class="fs-4">{{alias}}</div>
          <div>
            <nz-rate [(ngModel)]="overallRating" [nzDisabled]="true"></nz-rate>
            <span> {{reviewCount}} Reviews</span>
          </div>
          <div>
            <span>$$·Steak, Chinese</span>
          </div>
          <div>
            <i class="fas fa-map-marker-alt"></i><span> {{address}}</span>
          </div>
          <div>
            <span class="highlight">Open </span><span>11:30 AM - 7:30 PM</span>
          </div>
        </div>
        <div class="buttons">
          <button type="button" class="btn btn-outline-primary" (click)="onWriteReview('WriteReview')">
            <i class="far fa-star"></i> Write a Review
          </button>
          <button type="button" class="btn btn-outline-primary">
            <i class="fas fa-camera"></i> Add Photo</button>
          <button type="button" class="btn btn-outline-primary">
            <i class="far fa-share-square"></i> Share</button>
          <button *ngIf="!likeByUser; else likedByUser" type="button" class="btn btn-outline-primary" (click)="onLikeClick()">
            <i class="far fa-heart"></i> Like </button>
          <ng-template #likedByUser>
            <button type="button" class="btn btn-outline-primary" (click)="onUnlikeClick()">
              <i class="fas fa-heart"></i> Unlike </button>
            </ng-template>
          <button type="button" class="btn btn-outline-primary">
            <i class="fas fa-plus"></i> Follow</button>
        </div>
        <img style="width: 100%" *ngIf="banner" src="{{banner}}" />
        <img style="width: 100%" *ngIf="!banner&&photos" src="{{photos[0]}}" />
        <hr>
        <div>
          <div class="fs-3">Location</div>
          <div class="row">
            <div class="col-lg-9">
              <iframe width="100%" height="450" frameborder="0" style="border:0" [src]="googleMapUrl" allowfullscreen></iframe>
              <!-- <a href="#" class="btn btn-primary"><i class="fas fa-directions"></i> Get Directions</a> -->
            </div>
            <!-- <div class="col-lg-3 time">
              <div><b>Mon</b> 11:30 am - 7:30 pm</div>
              <div><b>Tue</b> 11:30 am - 7:30 pm</div>
              <div><b>Wed</b> 11:30 am - 7:30 pm</div>
              <div><b>Thu</b> 11:30 am - 7:30 pm</div>
              <div><b>Fri</b> 11:30 am - 7:30 pm</div>
              <div><b>Sat</b> 11:30 am - 7:30 pm</div>
              <div><b>Sun</b> 11:30 am - 7:30 pm</div>
            </div> -->
          </div>
        </div>
        <!--Photo Browser-->
        <hr *ngIf="photos">
        <div *ngIf="photos">
          <div class="fs-3">Photos</div>
          <nz-image-group>
            <div *ngFor="let photo of photos">
              <img nz-image width="150px" nzSrc="{{photo}}" alt="" />
            </div>
          </nz-image-group>
        </div>
        <hr *ngIf="description">
        <div *ngIf="description">
          <div class="fs-3">About the Business</div>
          <p>{{description}}</p>
        </div>
        <hr>
        <!--Reviews-->
        <div>
          <div class="fs-3">Reviews</div>
          <div *ngFor="let review of reviews" class="row" style="border: 1px solid #ccc; margin: 0.3rem; padding: 0.3rem; border-radius: 8px;">
            <div class="col-5">
              <img src="{{review.user.avatar.url}}" class="rounded-circle"
                alt="thumbnail" style="float: left; width: 30%" />
              <div style="float: right; width: 60%; font-size: 12px;">
                <div class="fs-5">{{review.user.username}}</div>
                <i class="fas fa-user-friends highlight"></i><span><b>21</b> Friends</span><br>
                <i class="far fa-star highlight"></i><span><b>33</b> Reviews</span><br>
                <i class="fas fa-heart highlight"></i><span><b>43</b> Likes</span>
              </div>
            </div>
            <div class="col-7">
              <div>
                <nz-rate [(ngModel)]="review.rating" [nzDisabled]="true"></nz-rate>
                <span> {{review.createdAt | date:'short'}} </span>
              </div>
              <!-- <div>
                <i class="fas fa-photo-video"></i><span>2 Photos</span>
              </div> -->
              <div>
                <span><b>{{review.title}}</b></span>
              </div>
              <div class="review">
                {{review.content}}
              </div>
              <nz-image-group>
                <div *ngFor="let image of review.images">
                  <img nz-image width="150px" nzSrc="{{image.url}}" alt="{{image.alternativeText}}" />
                </div>
              </nz-image-group>
            </div>
          </div>
          <hr *ngIf="userId">
          <div id="WriteReview" *ngIf="userId" class="row">
            <div class="col-12 review">
              <div style="border: 1px solid #ccc; padding: 0.01em 16px; border-radius: 8px;">
                <nz-rate [(ngModel)]="rate" style="margin-top: 1.2em; margin-bottom: 1.2em;"></nz-rate>
              </div>
              <input nz-input placeholder="Title" [(ngModel)]="reviewTitle" />
              <br />
              <textarea rows="4" placeholder="Review" nz-input [(ngModel)]="reviewContent"></textarea>
              <br />
              <div class="clearfix" *ngIf="reviewTitle && reviewContent">
                <nz-upload
                  nzAccept="image/png, image/jpeg"
                  nzListType="picture-card"
                  [nzCustomRequest]="handleUpload"
                  [(nzFileList)]="reviewUploadImages"
                  [nzShowButton]="reviewUploadImages.length < 8"
                  [nzPreview]="handlePreview"
                  [nzBeforeUpload]="beforeUpload"
                  [nzRemove]=""
                  [nzShowUploadList]="{showPreviewIcon: true, showRemoveIcon: true, showDownloadIcon: false}"
                >
                  <div>
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    <div style="margin-top: 8px">Add Image</div>
                  </div>
                </nz-upload>
                <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null" (nzOnCancel)="previewVisible = false">
                  <ng-template #modalContent>
                    <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                  </ng-template>
                </nz-modal>
              </div>
              <br />
              <button style="float: right;" nz-button nzType="primary" [disabled]="!reviewTitle || !reviewContent" (click)="onSubmitReview()">Submit</button>
            </div>
          </div>
        </div>
        <hr>
        <!--Recommendation-->
        <div>
          <div class="fs-3">You Might Also Like</div>
          <div>
            <div class="row my-2">
              <div class="col-md-3">
                <img src="https://images.unsplash.com/photo-1533619043865-1c2e2f32ff2f" class="img-fluid rounded"
                alt="thumbnail" />
              </div>
              <div class="col-md-9">
                <div>
                  <i class="fas fa-ad highlight"></i><span>Tricolore Caffe & Catering</span>
                </div>
                <div>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star-half-alt highlight"></i>
                </div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-3">
                <img src="https://images.unsplash.com/photo-1533619043865-1c2e2f32ff2f" class="img-fluid rounded"
                alt="thumbnail" />
              </div>
              <div class="col-md-9">
                <div>
                  <span>Salad Due</span>
                </div>
                <div>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                </div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-3">
                <img src="https://images.unsplash.com/photo-1533619043865-1c2e2f32ff2f" class="img-fluid rounded"
                alt="thumbnail" />
              </div>
              <div class="col-md-9">
                <div>
                  <span>Baiano Pizzeria</span>
                </div>
                <div>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                  <i class="fa fa-star highlight"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Right side bar-->
    <div class="col-lg-3 sidebar" data-spy="affix" data-offset-top="205"> 
      <div class="side-block contact">
        <div *ngIf="website"><a href="http://{{website}}" target="_blank"><i class="fas fa-external-link-alt"></i><span> {{website}} </span></a></div>
        <div *ngIf="phone"><i class="fas fa-phone-alt"></i><span> {{phone}} </span></div>
        <div><i class="fas fa-bars"></i><span> Full Menu </span></div>
      </div>
      <div *ngIf="category!='Restaurant'; else restaurant" class="side-block">
        <div class="fs-3">REQUEST A QUOTE</div>
        <div>
          <div style="display: inline-block">Response Time <p style="text-align: center;">5 minutes</p></div>
          <nz-divider nzType="vertical"></nz-divider>
          <div style="display: inline-block">Response Rate <p style="text-align: center;">90%</p></div>
        </div>
        <button class="btn btn-primary">Request a Quote</button>
      </div>
      <ng-template #restaurant>
        <div  class="side-block">
          <div class="fs-3">Order Food</div>
          <nz-tabset>
            <nz-tab nzTitle="Delivery">
              <div>Deliver fee $2.99</div>
              <div>Deliver time 40-50 mins</div>
              <form class="form-group">
                <label for="deliveryAddress">Deliver Address</label>
                <input type="text" id="deliveryAddress" name="address">
              </form>
            </nz-tab>
            <nz-tab nzTitle="Takeout">
              <span>Please call us and order</span>
            </nz-tab>
          </nz-tabset>
          <button class="btn btn-primary">Start Order</button>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #loadingMerchant>
    <i nz-icon [nzType]="'loading'"></i>
  </ng-template>
</div>

<nz-back-top></nz-back-top>
