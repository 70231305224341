import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import gql from 'graphql-tag';

const UserQuery = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      username
      fullname
      email
      avatar {
        url
      }
      createdAt
      location {
        city
        state
      }
      description
      socialMedia {
        name
        url
      }
    }
  }
`;

const UpdateUser = gql`
  mutation UpdateUser($input: updateUserInput) {
    updateUser(input: $input) {
      user {
        fullname
        location {
          city
          state
        }
        description
      }
    }
  }
`;

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditPage implements OnInit {

  loading: boolean = true;
  fullname: string;
  avatar: string;
  city: string;
  state: string;
  description: string;

  private userSubscription: Subscription;

  constructor(private router: Router, private apollo: Apollo) {}

  ngOnInit(): void {
    this.userSubscription = this.apollo
      .watchQuery<any>({
        query: UserQuery,
        variables: {
          id: localStorage.getItem('user'),
        },
      }).valueChanges.subscribe(({ data, loading }) => {
        this.loading = loading;
        const { fullname, avatar, location, description } = data.user;
        this.fullname = fullname;
        this.avatar = avatar.url;
        this.city = location.city;
        this.state = location.state;
        this.description = description || `You haven't told us yet...`;
      });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  onUpdateProfile() {
    this.apollo.mutate({
      mutation: UpdateUser,
      variables: {
        input:{
          where: {
            id: localStorage.getItem('user')
          },
          data: {
            fullname: this.fullname,
            location: {
              city: this.city,
              state: this.state
            },
            description: this.description
          }
        }
      }
    }).subscribe(({ data }) => {
      this.router.navigateByUrl('/account');
    },(error) => {
      console.log('Update profile error', error);
    });
  }
}
