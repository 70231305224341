<!-- Main Site Block -->
<app-main-site-block></app-main-site-block>

<!-- Most Visited Places -->
<div class="site-section" data-aos="fade">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-7 text-center border-primary">
        <h2 class="font-weight-light text-primary">{{ 'CONTENT.MOST_VISIT_PLACES_TITLE' | translate }}</h2>
        <p class="color-black-opacity-5">{{ 'CONTENT.MOST_VISIT_PLACES_TEXT' | translate }}</p>
      </div>
    </div>
    <div class="my-2">
      <app-banner></app-banner>
    </div>
    <app-listing-items></app-listing-items>
  </div>
</div>

<!-- COMMERCIAL CONDOS -->
<div class="site-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-7 text-center border-primary">
        <h2 class="font-weight-light text-primary">{{ 'CONTENT.COMMERCIAL_CONDOS' | translate }}</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-6 col-lg-3 p-2">
        <!-- Article -->
        <article class="card text-center g-bg-white g-brd-around g-brd-gray-light-v3 g-rounded-4 g-my-2">
          <!-- Article Header -->
          <header class="card-header">
            <div class="landing-block-node-card-subtitle text-uppercase g-letter-spacing-1 g-color-gray-dark-v5 g-mb-15">
              <span style="font-weight: bold;">$3,800,000</span></div>
            <div class="card-title text-uppercase g-line-height-1_4 g-font-weight-700 g-font-size-16 g-mb-10">
              GROUND FLOOR MEDICAL OFFICE 1A
            </div>
            <div class="text-uppercase g-font-size-12">
              <a class="landing-block-node-card-link g-font-size-10 g-color-gray-dark-v5 g-color-primary--hover g-text-underline--none--hover" href="https://www.doufish.com/sales" target="_popup">{{'MENU.LEARN_MORE' | translate}}</a>
            </div>
          </header>
          <!-- End Article Header -->

          <!-- Article Image -->
          <a href="https://www.doufish.com/sales">
            <img class="landing-block-node-card-img w-100" src="https://static.wixstatic.com/media/9968cd_89a7a4f0b484423aa8c65480e6f4d2f6~mv2.png" alt="GROUND FLOOR MEDICAL OFFICE 1A 2306SF"
              data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}" data-fileid="97" data-fileid2x="99">
          </a>
          <!-- End Article Image -->

          <!-- Article Footer -->
          <footer class="card-footer d-table w-100">
            <div class="landing-block-node-card-texticon-container g-color-gray-dark-v5 d-table-cell g-px-10 g-pt-15 g-pb-10">
              <div class="landing-block-node-card-icon-container g-mr-5 g-font-size-18 d-inline-block g-valign-middle">
                <i class="landing-block-node-card-icon3 u-line-icon-pro icon-real-estate-017" data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}"></i>
              </div>
              <div class="landing-block-node-card-icon-text landing-block-node-card-icon-text3 g-font-size-12 g-valign-middle d-inline">
                2306 {{'CONTENT.SQFT' | translate}}</div>
            </div>
          </footer>
          <!-- End Article Footer -->
        </article>
        <!-- End Article -->
      </div>
      <div class="col-sm-6 col-lg-3 p-2">
        <!-- Article -->
        <article class="card text-center g-bg-white g-brd-around g-brd-gray-light-v3 g-rounded-4 g-my-2" style="animation-duration: 1000ms; animation-play-state: running; animation-name: none;">
          <!-- Article Header -->
          <header class="card-header">
            <div class="landing-block-node-card-subtitle text-uppercase g-letter-spacing-1 g-color-gray-dark-v5 g-mb-15">
              <span style="font-weight: bold;">$3,800,000</span></div>
            <div class="card-title text-uppercase g-line-height-1_4 g-font-weight-700 g-font-size-16 g-mb-10">
              GROUND FLOOR medical office 1b
            </div>
            <div class="text-uppercase g-font-size-12">
              <a class="landing-block-node-card-link g-font-size-10 g-color-gray-dark-v5 g-color-primary--hover g-text-underline--none--hover" href="https://www.doufish.com/sales" target="_popup">{{'MENU.LEARN_MORE' | translate}}</a>
            </div>
          </header>
          <!-- End Article Header -->

          <!-- Article Image -->
          <a href="https://www.doufish.com/sales">
            <img class="landing-block-node-card-img w-100" src="https://static.wixstatic.com/media/9968cd_f72310c9e41a4b9a9bbf333c612e0f86~mv2.jpg" alt="GROUND FLOOR medical office 1b"
              data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}" data-fileid="93" data-fileid2x="95">
          </a>
          <!-- End Article Image -->

          <!-- Article Footer -->
          <footer class="card-footer d-table w-100">
            <div class="landing-block-node-card-texticon-container g-color-gray-dark-v5 d-table-cell g-px-10 g-pt-15 g-pb-10">
              <div class="landing-block-node-card-icon-container g-mr-5 g-font-size-18 d-inline-block g-valign-middle">
                <i class="landing-block-node-card-icon3 u-line-icon-pro icon-real-estate-017" data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}"></i>
              </div>
              <div class="landing-block-node-card-icon-text landing-block-node-card-icon-text3 g-font-size-12 g-valign-middle d-inline">
                2306 {{'CONTENT.SQFT' | translate}}</div>
            </div>
          </footer>
          <!-- End Article Footer -->
        </article>
        <!-- End Article -->
      </div>
      <div class="col-sm-6 col-lg-3 p-2">
        <!-- Article -->
        <article class="card text-center g-bg-white g-brd-around g-brd-gray-light-v3 g-rounded-4 g-my-2" style="animation-duration: 1000ms; animation-play-state: running; animation-name: none;">
          <!-- Article Header -->
          <header class="card-header">
            <div class="landing-block-node-card-subtitle text-uppercase g-letter-spacing-1 g-color-gray-dark-v5 g-mb-15">
              <span style="font-weight: bold;">$2,150,000</span></div>
            <div class="card-title text-uppercase g-line-height-1_4 g-font-weight-700 g-font-size-16 g-mb-10">
              second floor medical office 2a
            </div>
            <div class="text-uppercase g-font-size-12">
              <a class="landing-block-node-card-link g-font-size-10 g-color-gray-dark-v5 g-color-primary--hover g-text-underline--none--hover" href="https://www.doufish.com/sales" target="_popup">{{'MENU.LEARN_MORE' | translate}}</a>
            </div>
          </header>
          <!-- End Article Header -->

          <!-- Article Image -->
          <a href="https://www.doufish.com/sales">
            <img class="landing-block-node-card-img w-100" src="https://static.wixstatic.com/media/9968cd_036efb71d6f84b11b973227ca5ad9a61~mv2.jpg" alt="second floor medical office 2a"
              data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}" data-fileid="101" data-fileid2x="103">
          </a>
          <!-- End Article Image -->

          <!-- Article Footer -->
          <footer class="card-footer d-table w-100">
            <div class="landing-block-node-card-texticon-container g-color-gray-dark-v5 d-table-cell g-px-10 g-pt-15 g-pb-10">
              <div class="landing-block-node-card-icon-container g-mr-5 g-font-size-18 d-inline-block g-valign-middle">
                <i class="landing-block-node-card-icon3 u-line-icon-pro icon-real-estate-017" data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}"></i>
              </div>
              <div class="landing-block-node-card-icon-text landing-block-node-card-icon-text3 g-font-size-12 g-valign-middle d-inline">
                1159 {{'CONTENT.SQFT' | translate}}</div>
            </div>
          </footer>
          <!-- End Article Footer -->
        </article>
        <!-- End Article -->
      </div>
      <div class="col-sm-6 col-lg-3 p-2">
        <!-- Article -->
        <article class="card text-center g-bg-white g-brd-around g-brd-gray-light-v3 g-rounded-4 g-my-2" style="animation-duration: 1000ms; animation-play-state: running; animation-name: none;">
          <!-- Article Header -->
          <header class="card-header">
            <div class="landing-block-node-card-subtitle text-uppercase g-letter-spacing-1 g-color-gray-dark-v5 g-mb-15">
              <span style="font-weight: bold;">$2,150,000</span></div>
            <div class="card-title text-uppercase g-line-height-1_4 g-font-weight-700 g-font-size-16 g-mb-10">
              second floor medical office 2b
            </div>
            <div class="text-uppercase g-font-size-12">
              <a class="landing-block-node-card-link g-font-size-10 g-color-gray-dark-v5 g-color-primary--hover g-text-underline--none--hover" href="https://www.doufish.com/sales" target="_popup">{{'MENU.LEARN_MORE' | translate}}</a>
            </div>
          </header>
          <!-- End Article Header -->

          <!-- Article Image -->
          <a href="https://www.doufish.com/sales">
            <img class="landing-block-node-card-img w-100" src="https://static.wixstatic.com/media/9968cd_1249cb5211a6449fa127159de0b620ac~mv2.jpg" alt="second floor medical office 2b"
              data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}" data-fileid="105" data-fileid2x="107">
          </a>
          <!-- End Article Image -->

          <!-- Article Footer -->
          <footer class="card-footer d-table w-100">
            <div class="landing-block-node-card-texticon-container g-color-gray-dark-v5 d-table-cell g-px-10 g-pt-15 g-pb-10">
              <div class="landing-block-node-card-icon-container g-mr-5 g-font-size-18 d-inline-block g-valign-middle">
                <i class="landing-block-node-card-icon3 u-line-icon-pro icon-real-estate-017" data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}"></i>
              </div>
              <div class="landing-block-node-card-icon-text landing-block-node-card-icon-text3 g-font-size-12 g-valign-middle d-inline">
                1159 {{'CONTENT.SQFT' | translate}}</div>
            </div>
          </footer>
          <!-- End Article Footer -->
        </article>
        <!-- End Article -->
      </div>
    </div>
  </div>
</div>

<!-- RESIDENTIAL CONDOS -->
<div class="site-section">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-md-7 text-center border-primary">
        <h2 class="font-weight-light text-primary">{{ 'CONTENT.RESIDENTIAL_CONDOS' | translate }}</h2>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-sm-12 col-md-6">
        <!-- Article -->
        <article class="card text-center g-bg-white g-brd-around g-brd-gray-light-v3 g-rounded-4 g-my-2">
          <!-- Article Header -->
          <header class="card-header">
            <div class="landing-block-node-card-subtitle text-uppercase g-letter-spacing-1 g-color-gray-dark-v5 g-mb-15">
              <span style="font-weight: bold;">STARTING AT $1.25M</span></div>
            <div class="card-title text-uppercase g-line-height-1_4 g-font-weight-700 g-font-size-16 g-mb-10">
              1 BEDROOM CONDO
            </div>
          </header>
          <!-- End Article Header -->

          <!-- Article Image -->
          <a href="https://b24-eym2f0.bitrix24.site/">
            <img class="landing-block-node-card-img w-100" src="https://cdn.bitrix24.com/b14204529/landing/4a3/4a38da627cc70d45fdc89947231499a0/R-LK3sqLiBw_1x.jpg" alt="" srcset="https://cdn.bitrix24.com/b14204529/landing/1b5/1b59a2dbb0acd12f10e75c3dcf6589a0/R-LK3sqLiBw_2x.jpg 2x"
              data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}" data-fileid="97" data-fileid2x="99">
          </a>
          <!-- End Article Image -->

          <!-- Article Footer -->
          <footer class="card-footer d-table w-100">
            <div class="landing-block-node-card-texticon-container g-color-gray-dark-v5 d-table-cell g-px-10 g-pt-15 g-pb-10">
              <div class="landing-block-node-card-icon-container g-mr-5 g-font-size-18 d-inline-block g-valign-middle">
                <i class="landing-block-node-card-icon3 u-line-icon-pro icon-real-estate-017" data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}"></i>
              </div>
              <div class="landing-block-node-card-icon-text landing-block-node-card-icon-text3 g-font-size-12 g-valign-middle d-inline">
                130 {{'CONTENT.SQFT' | translate}}</div>
            </div>
          </footer>
          <!-- End Article Footer -->
        </article>
        <!-- End Article -->
      </div>
      <div class="col-sm-12 col-md-6">
        <!-- Article -->
        <article class="card text-center g-bg-white g-brd-around g-brd-gray-light-v3 g-rounded-4 g-my-2" style="animation-duration: 1000ms; animation-play-state: running; animation-name: none;">
          <!-- Article Header -->
          <header class="card-header">
            <div class="landing-block-node-card-subtitle text-uppercase g-letter-spacing-1 g-color-gray-dark-v5 g-mb-15">
              <span style="font-weight: bold;">STARTING AT $1.75M</span></div>
            <div class="card-title text-uppercase g-line-height-1_4 g-font-weight-700 g-font-size-16 g-mb-10">
              2 BEDROOM CONDO
            </div>
          </header>
          <!-- End Article Header -->

          <!-- Article Image -->
          <a href="https://b24-eym2f0.bitrix24.site/">
            <img class="landing-block-node-card-img w-100" src="https://cdn.bitrix24.site/bitrix/images/landing/business/800x534/img4.jpg" alt="" data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}"
              data-fileid="93" data-fileid2x="95">
          </a>
          <!-- End Article Image -->

          <!-- Article Footer -->
          <footer class="card-footer d-table w-100">
            <div class="landing-block-node-card-texticon-container g-color-gray-dark-v5 d-table-cell g-px-10 g-pt-15 g-pb-10">
              <div class="landing-block-node-card-icon-container g-mr-5 g-font-size-18 d-inline-block g-valign-middle">
                <i class="landing-block-node-card-icon3 u-line-icon-pro icon-real-estate-017" data-pseudo-url="{&quot;text&quot;:&quot;&quot;,&quot;href&quot;:&quot;&quot;,&quot;target&quot;:&quot;_self&quot;,&quot;enabled&quot;:false}"></i>
              </div>
              <div class="landing-block-node-card-icon-text landing-block-node-card-icon-text3 g-font-size-12 g-valign-middle d-inline">
                130 {{'CONTENT.SQFT' | translate}}</div>
            </div>
          </footer>
          <!-- End Article Footer -->
        </article>
        <!-- End Article -->
      </div>
    </div>
  </div>
</div>

<!-- Popular Categories -->
<div class="site-section">
  <div class="container">
    <div class="row justify-content-center m-2">
      <div class="col-md-7 text-center border-primary">
        <h2 class="font-weight-light text-primary">{{ 'CATEGORY.POPULAR' | translate }}</h2>
        <p class="color-black-opacity-5">{{ 'CATEGORY.POPULAR_TEXT' | translate }}</p>
      </div>
    </div>
    <app-popular-category-cards> </app-popular-category-cards>
    <div class="row m-2 justify-content-center tex-center">
      <div class="col-md-4"><a href="#" class="btn btn-block btn-outline-primary btn-md px-5">{{ 'CATEGORY.VIEW_ALL' | translate }}</a>
      </div>
    </div>
  </div>
</div>

<!-- How it works -->
<div class="site-section">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-md-7 text-center border-primary">
        <h2 class="font-weight-light text-primary">{{ 'CONTENT.HOW_IT_WORKS_TITLE' | translate }}</h2>
        <p class="color-black-opacity-5">{{ 'CONTENT.HOW_IT_WORKS_TEXT' | translate }}</p>
      </div>
    </div>

    <div class="row">
      <div class="m-2 m-lg-0 col-sm-12 col-md-6 col-lg-4">
        <div class="how-it-work-step">
          <div class="img-wrap">
            <img src="assets/images/step-1.svg" alt="Free website template by Free-Template.co" class="img-fluid">
          </div>
          <span class="number">1</span>
          <h3>{{ 'CONTENT.HOW_IT_WORKS_1_TITLE' | translate }}</h3>
          <p>{{ 'CONTENT.HOW_IT_WORKS_1_TEXT' | translate }}</p>
        </div>
      </div>
      <div class="m-2 m-lg-0 col-sm-12 col-md-6 col-lg-4">
        <div class="how-it-work-step">
          <div class="img-wrap">
            <img src="assets/images/step-2.svg" alt="Free website template by Free-Template.co" class="img-fluid">
          </div>
          <span class="number">2</span>
          <h3>{{ 'CONTENT.HOW_IT_WORKS_2_TITLE' | translate }}</h3>
          <p>{{ 'CONTENT.HOW_IT_WORKS_2_TEXT' | translate }}</p>
        </div>
      </div>
      <div class="m-2 m-lg-0 col-sm-12 col-md-6 col-lg-4">
        <div class="how-it-work-step">
          <div class="img-wrap">
            <img src="assets/images/step-3.svg" alt="Free website template by Free-Template.co" class="img-fluid">
          </div>
          <span class="number">3</span>
          <h3>{{ 'CONTENT.HOW_IT_WORKS_3_TITLE' | translate }}</h3>
          <p>{{ 'CONTENT.HOW_IT_WORKS_3_TEXT' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Sign Up -->
<app-signup-bar></app-signup-bar>

<nz-back-top></nz-back-top>
