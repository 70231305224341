import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from "lodash";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ListingItem } from '../../models/ListingItem';

const CategoriesWithMerchant = gql`
  query CategoriesWithMerchant {
    categories {
      id
      name
      merchants(limit: 1) {
        id
        name
        address1
        address2
        city
        state
      }
    }
  }
`;

@Component({
  selector: 'app-listing-items',
  templateUrl: './listing-items.component.html',
  styleUrls: ['./listing-items.component.css']
})
export class ListingItemsComponent implements OnInit, OnDestroy {
  @Input() listingItems: ListingItem[];
  categories: any[];
  loading = true;
  images: string[][];

  private querySubscription: Subscription;

  constructor(private apollo: Apollo, translate: TranslateService) {
    this.querySubscription = this.apollo
      .watchQuery<any>({
        query: CategoriesWithMerchant,
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.loading = loading;
        this.categories = data && data.categories;
        this.listingItems = [];
        for (let i = 0; i < this.categories.length; i++) {
          let cate = this.categories[i];
          if (!_.get(cate, 'merchants[0]')) continue;

          let item = {
            id: _.get(cate, 'merchants[0].id'),
            categoryId: cate.id,
            category: cate.name,
            categoryName: cate.name,
            imageUrl: `assets/images/img_${i + 1}.jpg`,
            title: _.get(cate, 'merchants[0].name'),
            city: _.get(cate, 'merchants[0].city'),
            state: _.get(cate, 'merchants[0].state'),
            rating: 0,
            reviewCount: 0,
          }
          if (translate.currentLang == 'zh') {
            item.categoryName = this.translateCategory(cate.name, 'zh');
          }
          this.listingItems.push(item);
        }
      });
    translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if (!this.listingItems) return;
        if (translate.currentLang == 'en') {
          let newListing = this.listingItems.map(item => {
            item.category = this.translateCategory(item.category, 'en');
            return item;
          }
          );
          this.listingItems = newListing;
        } else if (translate.currentLang == 'zh') {
          let newListing = this.listingItems.map(item => {
            item.category = this.translateCategory(item.category, 'zh');
            return item;
          }
          );
          this.listingItems = newListing;
        }
      }
    );
  }

  ngOnInit(): void {
    this.images = [
      [
        "https://images.unsplash.com/photo-1521403495585-a753f5d535b6",
        "https://images.unsplash.com/photo-1414235077428-338989a2e8c0",
        "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7"
      ],
      [
        "https://images.unsplash.com/photo-1414235077428-338989a2e8c0",
        "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7",
        "https://images.unsplash.com/photo-1521403495585-a753f5d535b6"
      ],
      [
        "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7",
        "https://images.unsplash.com/photo-1414235077428-338989a2e8c0",
        "https://images.unsplash.com/photo-1521403495585-a753f5d535b6"
      ],
      [
        "https://images.unsplash.com/photo-1414235077428-338989a2e8c0",
        "https://images.unsplash.com/photo-1521403495585-a753f5d535b6",
        "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7",
      ],
      [
        "https://images.unsplash.com/photo-1521403495585-a753f5d535b6",
        "https://images.unsplash.com/photo-1414235077428-338989a2e8c0",
        "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7",
      ],
      [
        "https://images.unsplash.com/photo-1521403495585-a753f5d535b6",
        "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7",
        "https://images.unsplash.com/photo-1414235077428-338989a2e8c0",
      ],
    ]
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  translateCategory(category: string, translateTo: string): string {
    if (translateTo == 'en') {
      switch (category) {
        case '餐廳': return 'Restaurant';
        case '金融': return 'Finance';
        case '健康': return 'Health';
        case '教育': return 'Education';
        case '交通運輸': return 'Transportation';
        case '娛樂': return 'Entertainment';
        default:
          return category;
      }
    } else if (translateTo == 'zh') {
      switch (category) {
        case 'Restaurant': return '餐廳';
        case 'Finance': return '金融';
        case 'Health': return '健康';
        case 'Education': return '教育';
        case 'Transportation': return '交通運輸';
        case 'Entertainment': return '娛樂';
        default:
          return category;
      }
    }
  }
}
