<footer class="site-footer">
  <div class="container">
    <div class="row">
      <div class="col-md-9 text-center">
        <div class="row">
          <div class="col-md-6 mb-5 mb-lg-0 col-lg-3">
            <h2 class="footer-heading mb-4">{{ 'MENU.QUICK_LINKS' | translate }}</h2>
            <ul class="list-unstyled">
              <li routerLinkActive="active"><a routerLink="/about"><span>{{ 'MENU.ABOUT_US' | translate }}</span></a></li>
              <li><a href="https://feedback.doudoufish.com/">{{ 'MENU.FEEDBACK' | translate }}</a></li>
              <li routerLinkActive="active"><a routerLink="/contact"><span>{{ 'MENU.CONTACT_US' | translate }}</span></a></li>
            </ul>
          </div>
          <div class="col-md-6 mb-5 mb-lg-0 col-lg-3">
            <h2 class="footer-heading mb-4">{{ 'MENU.PRODUCTS' | translate }}</h2>
            <ul class="list-unstyled">
              <li routerLinkActive="active"><a routerLink="/about"><span>{{ 'MENU.ABOUT_US' | translate }}</span></a></li>
              <li><a href="#">{{ 'MENU.SERVICES' | translate }}</a></li>
              <li routerLinkActive="active"><a routerLink="/contact"><span>{{ 'MENU.CONTACT_US' | translate }}</span></a></li>
            </ul>
          </div>
          <div class="col-md-6 mb-5 mb-lg-0 col-lg-3">
            <h2 class="footer-heading mb-4">{{ 'MENU.FEATURES' | translate }}</h2>
            <ul class="list-unstyled">
              <li routerLinkActive="active"><a routerLink="/about"><span>{{ 'MENU.ABOUT_US' | translate }}</span></a></li>
              <li><a href="#">{{ 'MENU.SERVICES' | translate }}</a></li>
              <li routerLinkActive="active"><a routerLink="/contact"><span>{{ 'MENU.CONTACT_US' | translate }}</span></a></li>
            </ul>
          </div>
          <div class="col-md-6 mb-5 mb-lg-0 col-lg-3">
            <h2 class="footer-heading mb-4">{{ 'MENU.FOLLOW_US' | translate }}</h2>
            <a href="#" class="pl-0 pr-3"><span class="icon-facebook"></span></a>
            <a href="#" class="pl-3 pr-3"><span class="icon-twitter"></span></a>
            <a href="#" class="pl-3 pr-3"><span class="icon-instagram"></span></a>
            <a href="#" class="pl-3 pr-3"><span class="icon-linkedin"></span></a>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <h2 class="footer-heading mb-4 text-center">{{ 'MENU.SUBSCRIBE_NEWSLETTER' | translate }}</h2>
        <p>{{ 'MENU.SUBSCRIBE_NEWSLETTER_TEXT' | translate }}</p>
        <form action="#" method="post">
          <div class="input-group mb-3">
            <input type="text" class="form-control bg-transparent" placeholder="{{ 'CONTENT.ENTER_EMAIL' | translate }}" aria-label="Enter Email"
              aria-describedby="button-addon2">
            <div class="input-group-append">
              <button class="btn btn-primary text-white" type="button" id="button-addon2">{{ 'CONTENT.SEND' | translate }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row pt-5 mt-5">
      <div class="col-12 text-center text-left">
        <p>
          {{ 'CONTENT.COPYRIGHT' | translate }} &copy; 2021 {{ 'CONTENT.RIGHTS' | translate }}
        </p>
      </div>
    </div>
  </div>
</footer>