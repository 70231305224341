import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input() images: string[];
  @Input() id: string;
  @Input() title: string;
  @Input() content: string;
  @Input() category: string;
  @Input() categoryDisplayName: string;
  @Input() imageLink: boolean;

  constructor() { }

  ngOnInit(): void {}

}
