<div class="py-5 bg-primary">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 mr-auto mb-4 mb-lg-0">
        <h2 class="mb-3 mt-0 text-white">{{ 'MENU.SIGN_UP_TITLE' | translate }}</h2>
        <p class="mb-0 text-white">{{ 'MENU.SIGN_UP_TEXT' | translate }}</p>
      </div>
      <div class="col-lg-4">
        <p class="mb-0"><a href="/signup"
            class="btn btn-outline-white text-white btn-md px-5 font-weight-bold btn-md-block">{{ 'MENU.SIGN_UP' | translate }}</a></p>
      </div>
    </div>
  </div>
</div>