<div class="site-blocks-cover overlay" style="background-image: url(assets/images/homepage.jpg);" data-aos="fade"
  data-stellar-background-ratio="0.5">
  <div class="container">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-md-10">
        <div class="row justify-content-center pb-4">
          <div class="col-md-10 text-center">
            <h1 data-aos="fade-up">{{ 'CONTENT.FIND_NEARBY' | translate }} <span id="typed-words"></span></h1>
            <p data-aos="fade-up" class="w-75 mx-auto">{{ 'CONTENT.MAIN_BLOCK_TEXT' | translate }}</p>
          </div>
        </div>
        <div class="form-search-wrap p-2" data-aos="fade-up" data-aos-delay="200">
          <form method="post">
            <div class="row align-items-center">
              <div class="col-lg-12 col-xl-4 no-sm-border border-right">
                <input type="text" class="form-control" placeholder="{{ 'CONTENT.WHAT_ARE_YOU_LOOKING_FOR' | translate }}" (change)="onTextChange($event.target.value)">
              </div>
              <div class="col-lg-12 col-xl-3 no-sm-border border-right">
                <div class="wrap-icon">
                  <span class="icon icon-room"></span>
                  <input type="text" class="form-control" placeholder="{{ 'CONTENT.LOCATION' | translate }}" (change)="onLocationChange($event.target.value)">
                </div>
              </div>
              <div class="col-lg-12 col-xl-3">
                <div class="select-wrap">
                  <span class="icon"><span class="icon-keyboard_arrow_down"></span></span>
                    <select class="form-control" (change)="onCategorySelect($event.target.value)">
                      <option *ngFor="let category of categories" [value]="category.key">{{ category.name }}</option>
                    </select>
                </div>
              </div>
              <div class="col-lg-12 col-xl-2 ml-auto text-right">
                <input type="submit" class="btn text-white btn-primary" value="{{ 'CATEGORY.SEARCH' | translate }}" (click)="onClickSearch()">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
