<div>
    <div *ngFor="let review of reviews" class="row"
        style="border: 1px solid #ccc; margin: 0.3rem; padding: 0.3rem; border-radius: 8px;">
        <div class="col-5">
            <div style="width: 40%; font-size: 12px;">
                <a routerLink="/merchant" [queryParams]="{id: review.merchant.id}"><h5>{{review.merchant.name}}</h5></a>
                <h5>{{review.merchant.alias}}</h5>
            </div>
        </div>
        <div class="col-7">
            <div>
                <!-- <nz-rate [(ngModel)]="review.rating" [nzDisabled]="true"></nz-rate> -->
                <span> {{review.createdAt | date:'short'}} </span>
            </div>
            <div>
                <span><b>{{review.title}}</b></span>
            </div>
            <div class="review">
                {{review.content}}
            </div>
            <!-- <nz-image-group>
                <div *ngFor="let image of review.images">
                    <img nz-image width="150px" nzSrc="{{image.url}}" alt="{{image.alternativeText}}" />
                </div>
            </nz-image-group> -->
        </div>
    </div>
    <div class="col-12 mt-5 text-center">
        <div>Find {{reviews.length}} reviews.</div>
    </div>
</div>
<router-outlet></router-outlet>
