<div class="site-blocks-cover inner-page-cover overlay" style="background-image: url(assets/images/homepage.jpg);"
  data-aos="fade" data-stellar-background-ratio="0.5">
  <div class="container">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-md-10" data-aos="fade-up" data-aos-delay="400">
        <div class="row justify-content-center">
          <div class="col-md-8 text-center">
            <h1>Tips & Articles</h1>
            <p data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipisicing
              elit.
              Cupiditate beatae quisquam perspiciatis adipisci ipsam quam.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-section">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="row mb-3 align-items-stretch">
          <div class="col-md-6 col-lg-6 mb-4 mb-lg-4">
            <div class="h-entry">
              <a href="blog-single.html"><img src="assets/images/img_1.jpg" alt="Image" class="img-fluid"></a>
              <div class="h-entry-inner">
                <h2 class="font-size-regular"><a href="blog-single.html">Etiquette tips for
                    travellers</a></h2>
                <div class="meta mb-4">by <a href="blog-single.html">Jeff Sheldon</a> <span class="mx-2">&bull;</span>
                  May 5th, 2019</div>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and
                  Consonantia, there live the blind texts. </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 mb-4 mb-lg-4">
            <div class="h-entry">
              <a href="blog-single.html"><img src="assets/images/img_2.jpg" alt="Image" class="img-fluid"></a>
              <div class="h-entry-inner">
                <h2 class="font-size-regular"><a href="blog-single.html">Etiquette tips for
                    travellers</a></h2>
                <div class="meta mb-4">by <a href="blog-single.html">Jeff Sheldon</a> <span class="mx-2">&bull;</span>
                  May 5th, 2019</div>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and
                  Consonantia, there live the blind texts. </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 mb-4 mb-lg-4">
            <div class="h-entry">
              <a href="blog-single.html"><img src="assets/images/img_3.jpg" alt="Image" class="img-fluid"></a>
              <div class="h-entry-inner">
                <h2 class="font-size-regular"><a href="blog-single.html">Etiquette tips for
                    travellers</a></h2>
                <div class="meta mb-4">by <a href="blog-single.html">Jeff Sheldon</a> <span class="mx-2">&bull;</span>
                  May 5th, 2019</div>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and
                  Consonantia, there live the blind texts. </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 mb-4 mb-lg-4">
            <div class="h-entry">
              <a href="blog-single.html"><img src="assets/images/img_4.jpg" alt="Image" class="img-fluid"></a>
              <div class="h-entry-inner">
                <h2 class="font-size-regular"><a href="blog-single.html">Etiquette tips for
                    travellers</a></h2>
                <div class="meta mb-4">by <a href="blog-single.html">Jeff Sheldon</a> <span class="mx-2">&bull;</span>
                  May 5th, 2019</div>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and
                  Consonantia, there live the blind texts. </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 mb-4 mb-lg-4">
            <div class="h-entry">
              <a href="blog-single.html"><img src="assets/images/img_5.jpg" alt="Image" class="img-fluid"></a>
              <div class="h-entry-inner">
                <h2 class="font-size-regular"><a href="blog-single.html">Etiquette tips for
                    travellers</a></h2>
                <div class="meta mb-4">by <a href="blog-single.html">Jeff Sheldon</a> <span class="mx-2">&bull;</span>
                  May 5th, 2019</div>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and
                  Consonantia, there live the blind texts. </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 mb-4 mb-lg-4">
            <div class="h-entry">
              <a href="blog-single.html"><img src="assets/images/img_6.jpg" alt="Image" class="img-fluid"></a>
              <div class="h-entry-inner">
                <h2 class="font-size-regular"><a href="blog-single.html">Etiquette tips for
                    travellers</a></h2>
                <div class="meta mb-4">by <a href="#">Jeff Sheldon</a> <span class="mx-2">&bull;</span> May 5th, 2019
                </div>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and
                  Consonantia, there live the blind texts. </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center mt-5">
          <p class="custom-pagination">
            <span>1</span>
            <a href="#">2</a>
            <a href="#">3</a>
            <span>...</span>
            <a href="#">20</a>
          </p>
        </div>
      </div>
      <div class="col-md-3 ml-auto">
        <div class="mb-5">
          <h3 class="h5 text-black mb-3">Search</h3>
          <form action="#" method="post">
            <div class="form-group d-flex">
              <input type="text" class="form-control" placeholder="Search keyword and hit enter...">
            </div>
          </form>
        </div>
        <div class="mb-5">
          <h3 class="h5 text-black mb-3">Popular Posts</h3>
          <ul class="list-unstyled">
            <li class="mb-2"><a href="#">Lorem ipsum dolor sit amet</a></li>
            <li class="mb-2"><a href="#">Quaerat rerum voluptatibus veritatis</a></li>
            <li class="mb-2"><a href="#">Maiores sapiente veritatis reprehenderit</a></li>
            <li class="mb-2"><a href="#">Natus eligendi nobis</a></li>
          </ul>
        </div>
        <div class="mb-5">
          <h3 class="h5 text-black mb-3">Recent Comments</h3>
          <ul class="list-unstyled">
            <li class="mb-2"><a href="#">Image</a> <em>in</em> <a href="#">Lorem ipsum dolor sit amet</a>
            </li>
            <li class="mb-2"><a href="#">Image</a> <em>in</em> <a href="#">Quaerat rerum voluptatibus
                veritatis</a></li>
            <li class="mb-2"><a href="#">Image</a> <em>in</em> <a href="#">Maiores sapiente veritatis
                reprehenderit</a></li>
            <li class="mb-2"><a href="#">Image</a> <em>in</em> <a href="#">Natus eligendi nobis</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Sign Up -->
<app-signup-bar></app-signup-bar>