import { Component, OnInit } from '@angular/core';
import Typed from 'typed.js';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-site-block',
  templateUrl: './main-site-block.component.html',
  styleUrls: ['./main-site-block.component.css']
})
export class MainSiteBlockComponent implements OnInit {
  selectedCategoryKey: string;
  location: string;
  searchText: string;
  categories =  [
    {
        "key": null,
        "name": "All",
    },
    {
        "key": "Restaurant",
        "name": "Restaurant",
    },
    {
        "key": "Entertainment",
        "name": "Entertainment",
    },
    {
        "key": "Finance",
        "name": "Finance",
    },
    {
        "key": "Education",
        "name": "Education",
    },
    {
        "key": "Health",
        "name": "Health",
    },
    {
        "key": "Transportation",
        "name": "Transportation",
    }
  ];
  constructor(translate: TranslateService, private router: Router) {
    translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if(translate.currentLang == 'en') {
          this.categories = [
            {
                "key": null,
                "name": "All",
            },
            {
                "key": "Restaurant",
                "name": "Restaurant",
            },
            {
                "key": "Entertainment",
                "name": "Entertainment",
            },
            {
                "key": "Finance",
                "name": "Finance",
            },
            {
                "key": "Education",
                "name": "Education",
            },
            {
                "key": "Health",
                "name": "Health",
            },
            {
                "key": "Transportation",
                "name": "Transportation",
            }
          ];
        } else if(translate.currentLang == 'zh') {
          this.categories = [
            {
                "key": null,
                "name": "全部",
            },
            {
                "key": "Restaurant",
                "name": "餐廳",
            },
            {
                "key": "Entertainment",
                "name": "娛樂",
            },
            {
                "key": "Finance",
                "name": "金融",
            },
            {
                "key": "Education",
                "name": "教育",
            },
            {
                "key": "Health",
                "name": "健康",
            },
            {
                "key": "Transportation",
                "name": "交通運輸",
            }
          ];
        }
        let strings = this.categories.slice(1).map(cat => cat.name);
        new Typed('#typed-words', {
          strings,
          typeSpeed: 80,
          backSpeed: 80,
          backDelay: 4000,
          startDelay: 1000,
          loop: false,
          showCursor: true
        });
      }
    );
  }

  ngOnInit(): void {
  }

  onTextChange(text: string): void {
    this.searchText = text;
  }

  onLocationChange(text: string): void {
    this.location = text;
  }

  onCategorySelect(category: any): void {
    this.selectedCategoryKey = category;
  }

  onClickSearch(): void {
    let queryString: string[] = [];
    if(this.selectedCategoryKey) {
      queryString.push(`category=${this.selectedCategoryKey}`);
    }
    if(this.searchText) {
      queryString.push(`text=${this.searchText}`);
    }
    if(this.location) {
      queryString.push(`location=${this.location}`);
    }
    this.router.navigateByUrl(`/listings?${queryString.join('&')}`);
  }
}
