import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';

const LikesQuery = gql`
  query QueryLikesByUser($user: ID!) {
    likes(where: { user: $user }) {
      id
      merchant {
        id
        name
        alias
        phone
        email
        address1
        address2
        city
        state
        zipcode
        category {
          name
        }
      }
    }
  }
`;

const DeleteLike = gql`
  mutation DeleteLikeById($likeId: ID!) {
    deleteLike(input: {where: {id: $likeId}}) {
      like {
        id
      }
    }
  }
`;

@Component({
  selector: 'app-user-likes',
  templateUrl: './user-likes.component.html',
  styleUrls: ['./user-likes.component.scss']
})
export class UserLikesPage implements OnInit {

  @Input() likes: any[] = [];
  page: number;
  pages: number[];
  count: number;
  loading: boolean = true;
  qParams = {};
  private likesSubscription: Subscription;
  private deleteLikeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.qParams = params;
      if(!params.page) {
        this.qParams = {...this.qParams, page:1};
      }
      this.page = params['page'] ? params['page'] - 1 : 0;
      this.likesSubscription = this.apollo
        .watchQuery<any>({
          query: LikesQuery,
          variables: {
            user: localStorage.getItem('user'),
          },
        }).valueChanges.subscribe(({ data, loading }) => {
          this.loading = loading;
          this.count = data.likes.length;
          if(this.count > 0) {
            data.likes.forEach(like => {
              let {id, name, alias, phone, email, address1, address2, city, state, zipcode, category } = like.merchant;
              let item = {
                likeId: like.id,
                id,
                title: name,
                alias,
                phone,
                email,
                address: address1 && `${address1} ${address2}`,
                city,
                state,
                zipcode,
                category: _.get(category, 'name')
              }
              this.likes.push(item);
            });
          }
        });
    });
  }

  ngOnDestroy() {
    this.likesSubscription.unsubscribe();
    this.deleteLikeSubscription.unsubscribe();
  }

  onDelele(id: string): void {
    this.deleteLikeSubscription = this.apollo
      .mutate({
        mutation: DeleteLike,
        variables: {
          likeId: id,
        },
      }).subscribe(({ data }) => {
        this.likes = this.likes.filter(item => item.likeId != id);
      });
  }
}
