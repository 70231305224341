<div class="site-blocks-cover inner-page-cover overlay"
    style="background-image: url(assets/images/homepage.jpg); height: 1rem;">
</div>
<div class="container">
    <div class="row">
        <div class="col-md-4">
            <div class="d-flex justify-content-center">
                <img nz-image class="rounded-circle" nzSrc="{{avatarUrl}}" [nzFallback]="fallback"/>
            </div>
            <div class="side-block">
                <div class="flex-container">
                    <button class="btn btn-warning">Follow</button>
                    <button class="btn btn-warning">Add Friend</button>
                </div>
            </div>
            <div class="d-flex justify-content-center border">
                <ul class="sidebar-nav">
                    <li>
                        <a href="/account"><i class="fas fa-user"></i> Profile</a>
                    </li>
                    <li>
                        <a href="/account"><i class="fas fa-user-friends"></i> Friends</a>
                    </li>
                    <li>
                        <a href="/account/likes"><i class="far fa-heart"></i> Likes</a>
                    </li>
                    <li>
                        <a href="/account/reviews"><i class="far fa-star"></i> Reviews</a>
                    </li>
                    <li>
                        <a href="/account"><i class="fas fa-camera"></i> Photos</a>
                    </li>
                    <li>
                        <a href="/account"><i class="fas fa-lightbulb"></i> Tips</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-8">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>