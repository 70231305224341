<div class="site-blocks-cover inner-page-cover overlay" style="background-image: url(assets/images/homepage.jpg);"
  data-aos="fade" data-stellar-background-ratio="0.5">
  <div class="container">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-md-10" data-aos="fade-up" data-aos-delay="400">
        <div class="row justify-content-center">
          <div class="col-md-8 text-center">
            <h1>{{'MENU.CONTACT_US' | translate}}</h1>
            <p data-aos="fade-up" data-aos-delay="100"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-7 mb-5"  data-aos="fade">
        <form action="#" class="p-5 bg-white">
          <div class="row form-group">
            <div class="col-md-6 mb-3 mb-md-0">
              <label class="text-black" for="fname">{{'CONTENT.FIRST_NAME' | translate}}</label>
              <input type="text" id="fname" class="form-control">
            </div>
            <div class="col-md-6">
              <label class="text-black" for="lname">{{'CONTENT.LAST_NAME' | translate}}</label>
              <input type="text" id="lname" class="form-control">
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <label class="text-black" for="email">{{'CONTENT.EMAIL' | translate}}</label>
              <input type="email" id="email" class="form-control">
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <label class="text-black" for="subject">{{'CONTENT.SUBJECT' | translate}}</label>
              <input type="subject" id="subject" class="form-control">
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <label class="text-black" for="message">{{'CONTENT.MESSAGE' | translate}}</label>
              <textarea name="message" id="message" cols="30" rows="7" class="form-control" placeholder="{{'CONTENT.MESSAGE_PLACEHOLDER' | translate}}"></textarea>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <input type="submit" value="{{'CONTENT.SEND_MESSAGE' | translate}}" class="btn btn-primary btn-md text-white">
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-5"  data-aos="fade" data-aos-delay="100">
        <div class="p-4 mb-3 bg-white">
          <p class="mb-0 font-weight-bold">{{'CONTENT.ADDRESS' | translate}}</p>
          <p class="mb-4">349 Cobalt Way
          <br>#303
          <br>Sunnyvale, CA 94085
          </p>
          <p class="mb-0 font-weight-bold">Bill Wu</p>
          <p class="mb-4"><a href="#">+1 408-421-0775</a></p>
          <p class="mb-0 font-weight-bold">{{'CONTENT.FAX' | translate}}</p>
          <p class="mb-4"><a href="#">+1 650-558-8967</a></p>
          <p class="mb-0 font-weight-bold">{{'CONTENT.EMAIL' | translate}}</p>
          <p class="mb-0"><a href="bill.wu@doudoufish.com">bill.wu@doudoufish.com</a></p>
        </div>
        <div class="p-4 mb-3 bg-white">
          <h3 class="h5 text-black mb-3">{{'CONTENT.MORE_INFO' | translate}}</h3>
          <p></p>
          <p><a href="#" class="btn btn-primary px-4 py-2 text-white">{{'MENU.LEARN_MORE' | translate}}</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
