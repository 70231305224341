/**
 * https://strapi.io/documentation/developer-docs/latest/plugins/users-permissions.html#providers
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_HOST } from '../../config';
import AuthService from 'src/app/service/auth.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingPage implements OnInit {

  authCallback: string = `${API_HOST}/auth/facebook/callback`;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if(params.access_token) {
        let authObj: Observable<object> = this.http.get<object>(`${this.authCallback}?access_token=${params.access_token}`, httpOptions);
        authObj.subscribe(auth => {
          localStorage.setItem('token', auth['jwt']);
          localStorage.setItem('username', auth['user'].username);
          localStorage.setItem('user', auth['user']._id);
          localStorage.setItem('email', auth['user'].email);
          this.authService.loginPerformedObserver.next();
          this.router.navigateByUrl('/account');
        });
      }
    });
  }

}
