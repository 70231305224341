import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const ReviewsQuery = gql`
  query QueryReviewsByUser($user: ID!) {
    reviews(where: { user: $user }) {
      id
      title
      content
      rating
      user {
          username
      }
      merchant {
          id
          name
          alias
      }
      images {
        url
        alternativeText
      }
      createdAt
      updatedAt
    }
  }
`;

@Component({
  selector: 'app-user-reviews',
  templateUrl: './user-reviews.component.html',
  styleUrls: ['./user-reviews.component.scss']
})
export class UserReviewsPage implements OnInit {
  @Input() reviews: any[] = [];
  count: number;
  loading: boolean = true;

  private reviewsSubscription: Subscription;

  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this.reviewsSubscription = this.apollo
      .watchQuery<any>({
        query: ReviewsQuery,
        variables: {
          user: localStorage.getItem('user'),
        },
      }).valueChanges.subscribe(({ data, loading }) => {
        this.loading = loading;
        this.count = data.reviews.length;
        this.reviews = data.reviews;
      });
  }

  ngOnDestroy() {
    this.reviewsSubscription.unsubscribe();
  }
}
