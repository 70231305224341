<div class="site-blocks-cover inner-page-cover overlay" style="background-image: url(assets/images/homepage.jpg);"
  data-aos="fade" data-stellar-background-ratio="0.5">
  <div class="container">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-md-10" data-aos="fade-up" data-aos-delay="400">
        <div class="row justify-content-center">
          <div class="col-md-8 text-center">
            <h1>Reset Password</h1>
            <p data-aos="fade-up" data-aos-delay="100"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-6 mb-5">
        <form (ngSubmit)="onSubmit()" class="p-5 bg-white" style="margin-top: -150px;">
          <div class="row form-group">
            <div class="col-md-12">
              <label class="text-black" for="pass1">{{'CONTENT.PASSWORD' | translate}}</label>
              <input type="password" class="form-control" id="pass1" name="password" [formControl]="password" required minlength="8">
              <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                <div *ngIf="password.errors.required">
                  Password is required.
                </div>
                <div *ngIf="password.errors.minlength">
                  Password must be at least 8 characters long.
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <label class="text-black" for="pass2">{{'CONTENT.RETYPE_PASSWORD' | translate}}</label>
              <input type="password" class="form-control" id="pass2" name="confirmPassword" [formControl]="confirmPassword" required minlength="8">
              <div *ngIf="confirmPassword.value != password.value" class="alert alert-danger">
                Retyped password must be same as password.
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <input type="submit" class="btn btn-primary btn-md text-white" [disabled]="confirmPassword.invalid || confirmPassword.value != password.value"/>
            </div>
          </div>
          <div *ngIf="status" class="alert alert-danger">
            {{status}}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>